import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { useLocation, Link } from "react-router-dom";

import { getPost } from '../helper/restapi.js';

import { Loader } from './Loader.js';
import { FormFree } from './Elements.js';

import 'lightbox2/dist/css/lightbox.css';
import 'lightbox2/dist/js/lightbox-plus-jquery.js';

function withParams(Component) {
    return props => <Component {...props} location={useLocation()} />;
}

const ShareButtons = ({ title, url }) => {
    const encodedTitle = encodeURIComponent(title);
    const encodedUrl = encodeURIComponent(url);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(url).then(() => {
            alert("Link copied to clipboard!");
        });
    };

    return (
        <div className="share-buttons text-end">
            <span>Teilen:</span>
            <a
                href={`https://wa.me/?text=${encodedTitle}%20${encodedUrl}`}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-whatsapp"
            >
                <SvgWa size="26" color="white" />
            </a>
            <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-facebook"
            >
                <SvgFb size="24" color="white" />
            </a>
            <button onClick={handleCopyLink} className="btn btn-copy">
                <SvgLink size="24" color="white" />
            </button>
            <a
                href={`mailto:?subject=${encodedTitle}&body=${encodedUrl}`}
                className="btn btn-mail"
            >
                <SvgMail size="24" color="white" />
            </a>
        </div>
    );
};

const SvgLink = (props) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 640 512" 
    width={props.size || "24"} 
    height={props.size || "24"} 
    fill={props.color || "currentColor"} 
    {...props}
  >
    <path d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z" />
  </svg>
);
const SvgMail= (props) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 640 512" 
    width={props.size || "24"} 
    height={props.size || "24"} 
    fill={props.color || "currentColor"} 
    {...props}
  >
    <path d="M64 112c-8.8 0-16 7.2-16 16l0 22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1l0-22.1c0-8.8-7.2-16-16-16L64 112zM48 212.2L48 384c0 8.8 7.2 16 16 16l384 0c8.8 0 16-7.2 16-16l0-171.8L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64l384 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128z"/>
  </svg>
);
const SvgWa= (props) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 640 512" 
    width={props.size || "24"} 
    height={props.size || "24"} 
    fill={props.color || "currentColor"} 
    {...props}
  >
   <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/>
  </svg>
);
const SvgFb= (props) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 640 512" 
    width={props.size || "24"} 
    height={props.size || "24"} 
    fill={props.color || "currentColor"} 
    {...props}
  >
        <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"/>
  </svg>
);

class Post extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            data: [],
            external: false
        };
        this.loadExternal = this.loadExternal.bind(this);
    }

    async getPost(slug) {
        const response = await getPost(slug);
        if (response.data.data) {
            this.setState({
                data: response.data.data,
                isReady: true
            })
        }
    }

    loadExternal() {
        localStorage.setItem('external', 1);
        this.setState({ external: 1 });
    }

    componentDidMount() {
        var slug = this.props.location.pathname;
        slug = '/' + slug.substring(slug.lastIndexOf('/') + 1);
        this.getPost(slug);
        if (localStorage.getItem('external') === '1') {
            this.setState({ external: 1})
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            var slug = this.props.location.pathname;
            slug = '/' + slug.substring(slug.lastIndexOf('/') + 1);
            this.getPost(slug);
        }
    }

    render() {

        const { data } = this.state;

        if (!this.state.isReady) {
            return (
                <Loader />
            );
        }

        var items = data.content;
        var imageMd = data.image.sizes['banner-start-md'];
        var imageLg = data.image.sizes['image-half'];

        if (data.date) {
            var styleDate = data.date.split('.');
            var styleDay = styleDate[0];
            var styleMonth = styleDate[1];
            var months = ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];
            styleMonth = months[styleMonth-1];
            var styleYear = styleDate[2];
        }

        return (
            <>
                <Helmet>
                    <title>{data.meta_title}</title>
                    <meta name="description" content={data.meta_description} />
                    { data.social_title &&
                        <meta property="og:title" content={data.social_title} />
                    }
                    { data.social_description &&
                        <meta property="og:description" content={data.social_description} />
                    }
                    { data.social_image &&
                        <meta property="og:image" content={data.social_image} />
                    }
                </Helmet>

                <div className="news-detail">
                    <div className="container">
                        <div className="inner">
                            <div className="row">
                                <div className="col-12 col-lg-6 left">
                                    <div className="left-inner sticky-top">
                                        { !data.date && 
                                            <h1>{data.title}</h1>
                                        }
                                        { data.date && 
                                            <h1>Konzerte <br />& Events</h1>
                                        }
                                        <img src={imageLg} alt="Radio Grün Weiß" />

                                        <div className="row">
                                            <div className="col-12 col-lg-8">
                                            { data.image.description &&
                                                <span className="caption">{data.image.description}</span>
                                            }
                                            </div>
                                            <div className="col-12 col-lg-4 text-lg-end">
                                            { data.image.caption &&
                                                <caption className="cap text-lg-end">© { data.image.caption }</caption>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 right">
                                    <div className="right-inner">
                                        <div className="row">
                                            <div className="col-4 date">{ data.publish }</div>
                                            <div className="col-8 text-end">
                                                <ShareButtons
                                                    title={data.title}
                                                    url={window.location.href}
                                                />
                                            </div>
                                        </div>
                                            { data.date && 
                                                <div className="event-top">
                                                    <div className="date-left">
                                                        <span className="day">{ styleDay }</span>
                                                        <span className="month">{ styleMonth }</span>
                                                        <span className="year">{ styleYear }</span>
                                                    </div>
                                                    <div className="date-right">
                                                        { data.date && 
                                                            <>
                                                                <span>
                                                                    {data.date}

                                                                    {data.date_to &&
                                                                        <> - {data.date_to}</>
                                                                    }
                                                                </span>
                                                            </>
                                                        }
                                                        { data.time && !data.date_to && 
                                                            <><span> - {data.time} Uhr</span></>
                                                        }
                                                        { data.date && 
                                                            <h2 className="event-info">{data.title}, {data.short}</h2>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            { !data.date && 
                                                <h2>{data.short}</h2>
                                            }
                                        
                                            {items && items.length > 0 && items.map((item, index) => (
                                                <div key={index} className="col-12 item">
                                                    { item.content_type == 'text' &&
                                                        <div className="news-text">
                                                            <div dangerouslySetInnerHTML={{ __html: item.text }} />
                                                        </div>
                                                    }
                                                    { item.content_type == 'social' &&
                                                        <>
                                                            { this.state.external &&
                                                                <div className={`news-social-media ${item['social-media'].includes('youtube') ? 'yt' : ''}`}>
                                                                    {item['social-media'].includes('tiktok') ? (
                                                                        <iframe
                                                                            src={`https://www.tiktok.com/player/v1/${item['social-media'].split('/').pop()}`}
                                                                            width="400"
                                                                            height="480"
                                                                            frameBorder="0"
                                                                            scrolling="no"
                                                                            allowTransparency="true">
                                                                        </iframe>
                                                                    ) : item['social-media'].includes('youtube') ? (
                                                                        <iframe
                                                                            src={item['social-media']}
                                                                            className="youtube"
                                                                            width="400"
                                                                            height="250"
                                                                            frameBorder="0"
                                                                            scrolling="no"
                                                                            allowTransparency="true">
                                                                        </iframe>
                                                                    ) : (
                                                                        <iframe
                                                                            src={item['social-media']}
                                                                            width="400"
                                                                            height="500"
                                                                            frameBorder="0"
                                                                            scrolling="no"
                                                                            allowTransparency="true">
                                                                        </iframe>
                                                                    )}

                                                                </div>
                                                            }

                                                            { !this.state.external &&
                                                                <div className="news-social-media-external">
                                                                    <p>Bei Klick wird dieser Inhalt von externen Servern geladen. Details siehe <a href="/datenschutzerklaerung" target="_blank">Datenschutzerklärung</a>.</p>
                                                                    <div onClick={this.loadExternal} className="btn btn-success">Externe Inhalte laden</div>
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                    { item.content_type == 'image' &&
                                                        <div className="news-image">
                                                            <picture>
                                                                <source media="(max-width: 767px)" srcSet={item.image.sizes['image-half']} />
                                                                <source media="(max-width: 768px)" srcSet={item.image.sizes['image-full']} />
                                                                <img src={item.image.sizes['image-full']} alt="Radio Grün Weiß" />

                                                            </picture>

                                                            <div className="row">
                                                                <div className="col-12 col-lg-8">
                                                                { item.image.description &&
                                                                    <span className="caption">{item.image.description}</span>
                                                                }
                                                                </div>
                                                                <div className="col-12 col-lg-4 text-lg-end">
                                                                { item.image.caption &&
                                                                    <caption className="cap text-lg-end">© { item.image.caption }</caption>
                                                                }
                                                                </div>
                                                            </div>

                                                            
                                                        </div>
                                                    }
                                                    { item.content_type == 'gallery' &&
                                                        <div className="news-gallery row">
                                                            {item.images.map((image, index) => (
                                                                <div className="col-4 mb-3">
                                                                    <a
                                                                        href={image.sizes['image-full']}
                                                                        className="news-gallery-image"
                                                                        data-lightbox="gallery" 
                                                                        data-title={image.description || ""}
                                                                    >
                                                                        <img src={image.sizes['gallery']} alt="Radio Grün Weiß" />
                                                                    </a>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    }
                                                    { item.content_type == 'video' &&
                                                        <div className="news-video">
                                                            <video width="320" height="240" controls>
                                                                <source src={item.file} type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                            
                                                        </div>
                                                    }
                                                    { item.content_type == 'audio' &&
                                                        <div className="news-audio">
                                                            <audio controls>
                                                                <source src={item.file} type="audio/mpeg" />
                                                                Your browser does not support the audio element.
                                                            </audio>
                                                            { item.filetext &&
                                                                <span className="caption">{item.filetext}</span>
                                                            }
                                                        </div>
                                                    }
                                                    { item.content_type == 'form' &&
                                                        <FormFree id={item.form} />
                                                    }
                                                </div>
                                            ))}


                                        { data.date && 

                                            <div className="event-detail">
                                                <h2>Details</h2>
                                                { data.date && 
                                                    <div><strong>Datum: </strong>
                                                        {data.date}
                                                        {data.date_to &&
                                                            <> - {data.date_to}</>
                                                        }
                                                    </div>
                                                }
                                                { data.time && 
                                                    <div><strong>Uhrzeit: </strong>
                                                        {data.time}
                                                        {data.time_to &&
                                                            <> - {data.time_to}</>
                                                        }
                                                        <span> Uhr</span>
                                                    </div>
                                                }
                                                { data.location.name &&
                                                    <>
                                                        <h3>Veranstaltungsort:</h3>
                                                        <div className="location">{ data.location.name }</div>
                                                        { data.location.street &&
                                                            <div className="street">{ data.location.street } { data.location.number }</div>
                                                        }   
                                                        { data.location.zip &&
                                                            <div className="city">{ data.location.zip } { data.location.city }</div>
                                                        }   
                                                        { data.location.web &&
                                                            <a className="web" href={ data.location.web }>{ data.location.web }</a>
                                                        }                                                   
                                                    </>
                                                }
                                            </div>  

                                        }

                                        { data.related && data.related.length > 0 &&
                                            <div className="row relates">
                                                <div className="col-12 mb-3">
                                                    <hr />
                                                    <h4>Das könnte Sie auch interessieren</h4>
                                                </div>

                                                <div className="col-12">
                                                    <div className="row related post-list">
                                                    {data.related.map((post, index) => (
                                                        <div key={index} className="col-12 col-md-6 post">
                                                            <Link to={`/post/${post.slug}`} title={post.title}>
                                                                <div className="inner">
                                                                <img src={post.image.sizes['news-list']} alt={post.title} />
                                                                <div className="info">
                                                                    <h3>{post.title}</h3>
                                                                    <div>{post.short}</div>
                                                                </div>
                                                                {post.type === 'post' && <span>News</span>}
                                                                {post.type === 'event' && <span>{post.date}</span>}
                                                                {post.type === 'job' && <span>Job</span>}
                                                                {post.type === 'ads' && <span className="small">Werbung</span>}
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    ))}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withParams(Post);