import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { useLocation } from "react-router-dom";

import { getPage } from '../helper/restapi.js';

import { Loader } from './Loader.js';
import { Elements } from './Elements.js';

function withParams(Component) {
  return props => <Component {...props} location={useLocation()} />;
}


class Page extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			data: [],
		};
	}

	async getPage(slug) {
		const response = await getPage(slug);


		if (response.data.data) {

		if (response.data.data.status == 404) {
			window.location.href = '/seite-nicht-gefunden';
		}

			this.setState({
				data: response.data.data,
				isReady: true
			})
		}
	}

	componentDidMount() {
		var slug = this.props.location.pathname;
		if (slug == '/') {
			slug = '/home';
		}
		this.getPage(slug);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			var slug = this.props.location.pathname;
			if (slug === '/') {
				slug = '/home';
			}
			this.setState({
				isReady: false
			});
			this.getPage(slug);
		}
	}

	render() {

		const { data } = this.state;


		if (!this.state.isReady) {
			return (
				<Loader />
			);
		}

		var navItems = data.content;

		return (
			<>
				<Helmet>
					<title>{data.meta_title}</title>
					<meta name="description" content={data.meta_description} />
				</Helmet>
				<Elements data={navItems} isPlaying={this.props.isPlaying} toggleIsPlaying={this.props.toggleIsPlaying} currentPlaying={this.props.currentPlaying} coverFlow={this.props.coverFlow} />
			</>
		);
	}

}

export default withParams(Page);
