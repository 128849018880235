import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link, useLocation } from "react-router-dom";
import Slider from "react-slick";
import proj4 from 'proj4';
import { supabase } from '../helper/supabaseClient';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { getFrequencies, getAllFrequencies, getSentPositions, getPosts, getRadiothek, sendFreeFormApi, getTeam, getVotings, sendVote, getTraffic, getProgram, getCurrentSongs, getOnAir, sendFormApi, getForm } from '../helper/restapi.js';

export function LiveLocations() {
	const [location, setLocation] = useState(null);

	// Function to update marker on the map for a single location
	const updateMarker = (location) => {
		const markersContainer = document.getElementById("markers-container");
		markersContainer.innerHTML = "";

		if (location) {
			const { lat, lon } = location;

			const marker = document.createElement("div");
			marker.className = "marker";

			const MIN_LONGITUDE = 13.561724;
			const MAX_LONGITUDE = 16.073340;
			const MIN_LATITUDE = 46.624794;
			const MAX_LATITUDE = 47.829133;

			const normalizedLongitude = (lon - MIN_LONGITUDE) / (MAX_LONGITUDE - MIN_LONGITUDE);
			const normalizedLatitude = (lat - MIN_LATITUDE) / (MAX_LATITUDE - MIN_LATITUDE);

			var markerXPercent = normalizedLongitude * 100;
			var markerYPercent = (1 - normalizedLatitude) * 100;

			if (markerXPercent > 100) {
				markerXPercent = 100;
			}
			if (markerXPercent < 0) {
				markerXPercent = 0;
			}
			if (markerYPercent > 100) {
				markerYPercent = 100;
			}
			if (markerYPercent < 0) {
				markerYPercent = 0;
			}

			marker.style.left = `${markerXPercent}%`;
			marker.style.top = `${markerYPercent}%`;
			markersContainer.appendChild(marker);
		}
	};

	useEffect(() => {
		const subscription = supabase
			.channel('public:locations')
			.on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'locations' }, (payload) => {
				setLocation(payload.new);  // Set the latest location
			})
			.subscribe();

		return () => {
			supabase.removeChannel(subscription);
		};
	}, []);

	useEffect(() => {
		updateMarker(location);
	}, [location]);

	return (
		<div>
			<div id="map-container">
				<img src="https://backend.gruen-weiss.at/wp-content/uploads/styria.png" alt="Styria Map" id="styria-map" />
				<div id="markers-container"></div>
			</div>
		</div>
	);
}



export function Elements({ data, isPlaying, toggleIsPlaying, currentPlaying, coverFlow }) {

		var elements = data;


	var i = 1;
	const items = elements.map((item, index) => (
		<div className="section" key={i++}>
			{item.layout === 'gallery_slider' ? (
				<GallerySlider data={item.data} />
			) : item.layout === 'block_start' ? (
				<BlockStart data={ item.data } i={ i } />
			) : item.layout === 'text' ? (
				<TextComponent data={ item.data } i={ i } />
			) : item.layout === 'history' ? (
				<History data={ item.data } i={ i } />
			) : item.layout === 'search' ? (
				<Search data={ item.data } i={ i } />
			) : item.layout === 'program' ? (
				<Program data={ item.data } i={ i } />
			) : item.layout === 'traffic' ? (
				<Traffic data={ item.data } i={ i } />
			) : item.layout === 'frequencies' ? (
				<Frequencies data={ item.data } i={ i } />
			) : item.layout === 'radiothek' ? (
				<RadioThek data={ item.data } i={ i } isPlaying={isPlaying} toggleIsPlaying={toggleIsPlaying} currentPlaying={currentPlaying} />
			) : item.layout === 'banner_start' ? (
				!item.data.styria ? (
					<BannerStart data={item.data} isPlaying={isPlaying} toggleIsPlaying={toggleIsPlaying} currentPlaying={currentPlaying} coverFlow={coverFlow} />
				) : (
					<BannerStyria data={item.data} />
				)
			) : item.layout === 'banner' ? (
				<Banner data={ item.data } i={ i } />
			) : item.layout === 'post_list' ? (
				<PostList data={ item.data } i={ i } />
			) : item.layout === 'team' ? (
				<Team data={ item.data } i={ i } />
			) : item.layout === 'voting' ? (
				<Voting data={ item.data } i={ i } />
			) : item.layout === 'form_contact' ? (
				<FormContact data={ item.data } i={ i } />
			) : (
				<div className="text-danger">{ item.layout }</div>
			)}
		</div>
	));

	return items;
}

export function GallerySlider({ data }) {

	var gallery = data;
	var text = gallery.text;
	var images = gallery.gallery;

	const sliderRef = useRef(null);

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		autoplay: true,
		speed: 700,
		autoplaySpeed: 4000,
		cssEase: "linear",
		touchThreshold: 100,
		lazyLoad: true,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 900,
				settings: {
					slidesToShow: 2,
				}
			},
		]
	};

	var i = 1;
	const items = images.map((item, index) => (
		<div key={i++}>
			<img style={{ margin: '0 10px' }} src={item.sizes.gallery} alt={item.alt} width={item.sizes['gallery-width']} height={item.sizes['gallery-height']} />
		</div>
	));

	return (
		<div className="gallery_slider_wrap">
			<div className="container">
				<div className="col-12 col-md-6 col-lg-4 col-xl-3 green">
					<div className="background"></div>
					<div className="inner">
						{text}
						<div className="nav-btns">
							<div className="nav-btn" onClick={() => sliderRef.current.slickPrev()}></div>
							<div className="nav-btn" onClick={() => sliderRef.current.slickNext()}></div>
						</div>
					</div>
				</div>
			</div>
			<Slider className="gallery_slider" ref={sliderRef} {...settings}>
				{items}
			</Slider>
		</div>
	);
}


export function BlockStart({ data, i }) {
	var content = data;
	
	return (
		<div className={`container ce_block_start`}>
			<div className="inside">
				<div className="row">
					<div className="col-12 col-xl-4 part">
						<Link to='/events'>
							<img className="icon" src="/icon/icon-events.svg" />
							<div className="hl">Veranstaltungen</div>
							<div className="text">Alle Stationen und Termine</div>
						</Link>
					</div>
					<div className="col-12 col-xl-4 part">
						<Link to='/verkehr'>
							<img className="icon" src="/icon/icon-traffic.svg" />
							<div className="hl">Verkehr</div>
							<div className="text">Aktuelle Meldungen und Dauerbaustellen</div>
						</Link>
					</div>
					<div className="col-12 col-xl-4 part">
						<Link to='/hitvoting'>
							<img className="icon" src="/icon/icon-schlager.svg" />
							<div className="hl">Radio Grün Weiß Hit-Voting</div>
							<div className="text">Wählen Sie Ihre Top20!</div>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

export function BlockStartOld({ data, i }) {
	var content = data;
	
	return (
		<div className={`container ce_block_start`}>
			<div className="row">
				<div className="col-12 col-lg-4 left pr-0">
					<div
						className="inside h-100"
						style={{ background: 'url(' + content.image.sizes.large + ')' }}
					>
						<div className="effect"></div>
					</div>
					<div className="hl"><span>Pro-</span><span>gramm</span></div>
				</div>
				<div className="col-12 col-lg-8 right">
					<div className="inside">
						<div className="part">
							<Link to='/events'>
								<img className="icon" src="/icon/icon-events.svg" />
								<div className="hl">Veranstaltungen</div>
								<div className="text">Alle Stationen und Termine</div>
							</Link>
						</div>
						<div className="part">
							<Link to='/verkehr'>
								<img className="icon" src="/icon/icon-traffic.svg" />
								<div className="hl">Verkehr</div>
								<div className="text">Aktuelle Meldungen und Dauerbaustellen</div>
							</Link>
						</div>
						<div className="part">
							<Link to='/schlagerhitvoting'>
								<img className="icon" src="/icon/icon-schlager.svg" />
								<div className="hl">Schlager Hit-Voting</div>
								<div className="text">Wählen Sie Ihre Top20!</div>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export function TextComponent({ data, i }) {
	var content = data;
	var headline = content.headline;
	var headlineType = content.headline_type;
	var text = content.text;

	const isValidHeadingType = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(headlineType);
	const Heading = isValidHeadingType ? headlineType : 'div';

	return (
		<div className={`container ce_text ${i === 1 ? 'first' : ''}`}>
			<Heading>{headline}</Heading>
			<div dangerouslySetInnerHTML={{ __html: text }} />
		</div>

	);
}

export function History({ data, i }) {
	var content = data;
	var headline = content.headline;
	var image = content.bild;
	var imageLg = image.sizes['image-half'];
	var years = content.years;

	return (

		<div className="news-detail">
			<div className="container">
				<div className="inner">
					<div className="row">
						<div className="col-12 col-lg-6 left">
							<div className="left-inner sticky-top">
								<h1>
									{ headline }
								</h1>
								<img src={imageLg} alt="Radio Grün Weiß" />
							</div>
						</div>
						<div className="col-12 col-lg-6 right">
							<div className="right-inner history">
								{years && years.length > 0 && years.map((item, index) => (
									<div key={index} className="col-12">
										<div className="history-item">
											<div className="year">{ item.year }</div>
											<div className="info" dangerouslySetInnerHTML={{ __html: item.info }} />
										</div>											
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	);
}

export function Search({ data, i }) {

	var content = data;
	var headline = content.headline;
	var text = content.text;
	var image = content.image;
	var imageLg = image.sizes['image-half'];

	const [songData, setSongData] = useState(null);
	const [dateTime, setDateTime] = useState(null);
	const location = useLocation();

	useEffect(() => {
		const getQueryParameters = () => {
			const queryParams = new URLSearchParams(location.search);
			const dateParam = queryParams.get('d');
			const timeParam = queryParams.get('t');

			if (dateParam && timeParam) {
				setDateTime(`?z=${dateParam}%20${timeParam}`);
			}
		};

		getQueryParameters();
	}, [location.search]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (dateTime) {
					const response = await getCurrentSongs(dateTime);
					if (response) {
						setSongData(response.data);
					}
				} else {
					const response = await getCurrentSongs('');
					if (response) {
						setSongData(response.data);
					}
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, [dateTime]);

	return (

		<div className="news-detail">
			<div className="container">
				<div className="inner">
					<div className="row">
						<div className="col-12 col-lg-6 left">
							<div className="left-inner sticky-top">
								<h1>
									{ headline }
								</h1>
								<img src={imageLg} alt="Radio Grün Weiß" />
							</div>
						</div>
						<div className="col-12 col-lg-6 right">
							<div className="right-inner">
								<div className="news-text">
									<div dangerouslySetInnerHTML={{ __html: text }} />
								</div>

								<SearchSongs />

								{ songData &&
									<div className="song-list">
										<div className="song-inner-head d-none d-lg-block">
											<div className="row align-items-center">
												<div className="col-lg-2">Cover</div>
												<div className="col-lg-10">
													<div className="row">
														<div className="col-lg-4">Zeit</div>
														<div className="col-lg-4">Interpret</div>
														<div className="col-lg-4">Titel</div>
													</div>
												</div>
											</div>
										</div>

										{ Object.entries(songData).map(([key, value]) => (
											<div className="song-inner" key={key}>
												<div className="row align-items-center">
													<div className="col-4 col-lg-2">
														<img src={value.cover} />
													</div>
													<div className="col-8 col-lg-10">
														<div className="row align-items-center">
															<div className="col-lg-3">
																<span className="d-block d-lg-none">Zeit:</span> {value.start && value.start.split(' ')[1]?.slice(0, 5)}
															</div>
															<div className="col-lg-5">
																<span className="d-block d-lg-none">Interpret: </span> {value.artist}
															</div>
															<div className="col-lg-4">
																<span className="d-block d-lg-none">Titel:</span> {value.title}
															</div>
														</div>
													</div>
												</div>
											</div>
										))}
									</div>
								}

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	);
}

export function Program({ data, i }) {

	const [program, setProgram] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getProgram();
				if (response) {
					setProgram(response.data.data);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, []);

	return (


		<div className="radio-program">
			{ program &&
				<div className="container">
					<div className="inner">
						<div className="row">
							{ Object.entries(program).map(([key, value]) => (
								<>
									{ key == 'mo' &&
										<div className="col-12 mb-4" key="d"><h2>Die Woche auf Radio Grün Weiß</h2></div>
									}
									{ key == 'sa' &&
										<div className="col-12 mb-4" key="d"><h2>Das Wochenende auf Radio Grün Weiß</h2></div>
									}
									<div className="col-12 col-lg-6 day" key={key}>

										<div className="day-name">{ key }</div>
										<div className="day-entries">
											{ Object.entries(value).map(([k, value]) => (
												<div className="day-entry" key={key + k}>
													<div className="time">{ value.time }</div>
													<div className="text">{ value.text }</div>
													{ value.info &&
														<div className="info">{ value.info }</div>
													}
												</div>
											))}	
										</div>
									</div>
								</>
							))}
						</div>
					</div>
				</div>
			}
		</div>

	);
}

export function Traffic({ data, i }) {
	const [infos, setInfos] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState('Alle'); // Default filter to "Alle"
	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getTraffic();

				if (response) {
					setInfos(response.data);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, []);

	// Extract unique categories from the messages
	const categories = Array.from(new Set(infos?.messages.map((message) => message.category)));

	// Handle category change in the filter
	const handleCategoryChange = (event) => {
		setSelectedCategory(event.target.value);
	};

	// Filter messages based on the selected category
	const filteredMessages = infos?.messages
		.filter((message) => message.state === 'active') // Filter by active state
		.filter((message) => selectedCategory === 'Alle' || message.category === selectedCategory); // Filter by selected category

	return (
		<div className={`container mb-5`}>
			<div className="row align-items-center mb-5">
				{infos && <img className="col-2 col-lg-1" src={infos.image} />}
				<h2 className="col-10 col-lg-11 mb-0">Verkehrsinformationen für die Steiermark</h2>
			</div>

			{infos && (
				<div className="row traffic mb-3">
					<div className="col-12 mb-0">
						<select className="form-select" value={selectedCategory} onChange={handleCategoryChange}>
							<option value="Alle">Alle Meldungen</option>
								{categories
								.filter((category) => category && category.trim() !== '') // Filter out empty or whitespace-only categories
								.map((category, index) => (
								<option key={index} value={category}>
									{category}
								</option>
								))}
						</select>
					</div>
				</div>
			)}

			{infos && (
			<div className="row traffic">
					<div className="col-12">
							<div className="table-responsive">
									<table className="table table-hover table-bordered mt-3">
											<thead>
													<tr>
															<th></th>
															<th>Ort</th>
															<th>Meldung</th>
													</tr>
											</thead>
											<tbody>
													{filteredMessages?.map((value, key) => (
															<tr key={key}>
																	<td>
																			<img className="icon" src={value.icon} />
																	</td>
																	<td>{value.title}</td>
																	<td>{value.description}</td>
															</tr>
													))}
											</tbody>
									</table>
							</div>
						<div class="c-rss__info">
							Informationen bereitgestellt von <a href="https://www.oeamtc.at/" target="_blank">ÖAMTC.AT</a>
						</div>
					</div>
			</div>

			)}
		</div>
	);
}




export function Frequencies({ data, i }) {
	const [frequencies, setFrequencies] = useState(null);
	const [currentImage, setCurrentImage] = useState(null);  // State to hold the current image

	const content = data;
	const headline = content.headline;
	const text = content.text;
	const images = content.images;  // Images array
	const firstImage = images[0];  // Default to the first image


	useEffect(() => {
			const fetchData = async () => {
					try {
							const response = await getAllFrequencies();
							if (response.data.data) {
									setFrequencies(response.data.data);
							}
					} catch (error) {
							console.error('Error fetching data:', error);
					}
			};

			fetchData();

			// Set the initial image to be the first image
			setCurrentImage(firstImage);

			// Wait until images are ready
			if (images.length === 0) return;

			// Wait for DOM elements with `data-type` to be available
			const waitForElements = setInterval(() => {
					const elements = document.querySelectorAll('[data-type]');
					if (elements.length > 0) {
							clearInterval(waitForElements); // Stop the interval once elements are found

							// IntersectionObserver to detect when sections with `data-type` are in view
							const observer = new IntersectionObserver((entries) => {
									entries.forEach(entry => {
											if (entry.isIntersecting) {
													const dataType = entry.target.getAttribute('data-type');
													
													// Find the image that corresponds to the `data-type`
													const image = images.find(img => img.type === dataType); 	                        
													// If an image is found for the `data-type`, set it as the current image
													if (image) {
															setCurrentImage(image);
													}
											}
									});
							}, { threshold: 0.5 }); // When at least 50% of the element is in the viewport

							// Observe all elements with `data-type`
							elements.forEach(element => observer.observe(element));

							// Cleanup observer on component unmount
							return () => {
									elements.forEach(element => observer.unobserve(element));
							};
					}
			}, 100); // Check every 100ms if `data-type` elements are available

			// Cleanup interval on component unmount
			return () => clearInterval(waitForElements);
	}, [images]);  // Run the effect whenever `images` changes


	return (
		<div className="news-detail">
			<div className="container">
				<div className="inner">
					<div className="row">
						<div className="col-12 col-lg-6 left">
							<div className="left-inner sticky-top">
								<h1>{headline}</h1>
								{/* Display the current image */}
								<img src={currentImage?.image.sizes['image-half']} alt={currentImage?.image.title} />
							</div>
						</div>
						<div className="col-12 col-lg-6 right">
							<div className="right-inner frequencies-wrap">
								<p>{text}</p>

								{frequencies !== null && (
									<>
										<div className="row mt-0">
											<div className="col-12 mb-5" data-type="ukw">
												<h2>Unsere UKW-Frequenzen</h2>
												<table className="table table-hover table-bordered mt-3">
													<thead>
														<tr>
															<th>Gebiet</th>
															<th className="text-end">Frequenz</th>
														</tr>
													</thead>
													<tbody>
														{Object.entries(frequencies.ukw).map(([key, value]) => (
															<tr key={key}>
																<td>{value.title}</td>
																<td className="text-end">{value.mhz} MHz</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>

											<div className="col-12 mb-5" data-type="dab">
												<h2>DAB+</h2>
												<table className="table table-hover table-bordered mt-3">
													<thead>
														<tr>
															<th>Gebiet</th>
															<th className="text-end">Kanal</th>
														</tr>
													</thead>
													<tbody>
														{Object.entries(frequencies.dab).map(([key, value]) => (
															<tr key={key}>
																<td>{value.title}</td>
																<td className="text-end">{value.mhz}</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>

											<div className="col-12 mb-5" data-type="app">
												<h2>Radio Grün Weiß App</h2>
												{Object.entries(frequencies.app).map(([key, value]) => (
													<div className="col-12" key={key}>
														<div dangerouslySetInnerHTML={{ __html: value.infotext }} />
														<div>
															<a href={value.link_android} title="Für Android laden">
																<img className="store-icon" src="/icon/google.png" />
															</a>
															<a href={value.link_ios} title="Für iPhone laden">
																<img className="store-icon" src="/icon/apple.png" />
															</a>
														</div>
													</div>
												))}
											</div>

											{Object.entries(frequencies.other).map(([key, value]) => (
												<div className="col-12 mb-5" key={key} data-type="other">
													<h2>{value.title}</h2>
													<div dangerouslySetInnerHTML={{ __html: value.infotext }} />
												</div>
											))}
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}



export function RadioThek({ data, i, isPlaying, toggleIsPlaying, currentPlaying }) {
    const content = data;
    const headline = content.headline;
    const headlineType = content.headline_type;
    const text = content.text;

    const [posts, setPosts] = useState(null);
    const [cats, setCats] = useState(null);
    const [filter, setFilter] = useState(null);
    const [popup, setPopup] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getRadiothek();
                if (response.data) {
                    setCats(response.data.serie);
                    handleFilter(null);
                    setPosts(response.data.data);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const slug = params.get("beitrag");

        if (slug && posts) {
            const foundPost = posts.find((post) => post.slug === slug);
            if (foundPost) setPopup(foundPost);
        }
    }, [location.search, posts]);

    const togglePlayPause = (post) => {
        toggleIsPlaying(post);
    };

    const handleFilter = (selectedFilter) => {
        setFilter(selectedFilter);
    };

    const openPopup = (post) => {
        setPopup(post);
        navigate(`?beitrag=${post.slug}`);
    };

    const closePopup = () => {
        setPopup(null);
        navigate("/radiothek", { replace: true }); // Remove query param without reloading
    };

    const isValidHeadingType = ["h1", "h2", "h3", "h4", "h5", "h6"].includes(headlineType);
    const Heading = isValidHeadingType ? headlineType : "div";

    return (
        <>
            <div className={`container ce_text ${i === 1 ? "first" : ""}`}>
                <Heading>{headline}</Heading>
                <div dangerouslySetInnerHTML={{ __html: text }} />
            </div>

            {popup && (
                <div className="sidebar">
                    <div onClick={closePopup} className="area-close"></div>
                    <div className="inner">
                        <div onClick={closePopup} className="close"></div>
                        {popup.image && <img src={popup.image.sizes["image-full"]} title={popup.title} />}
                        <h2 className="mt-4 mb-4">{popup.title}</h2>
                        <div onClick={() => togglePlayPause(popup)} className="btn-white">
                            <img className="play d-inline-block" src="/icon/btn-play.svg" />
                            Jetzt anhören
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: popup.text }} />
                    </div>
                </div>
            )}

            <div className="container filters mb-5">
                <div className="col-12">
                    <div
                        onClick={() => handleFilter(null)}
                        className={`filter btn-filter ${filter === null ? "active" : ""}`}
                    >
                        Alle Sendungen
                    </div>
                    {cats &&
                        Object.entries(cats).map(([key, value]) => (
                            <div
                                onClick={() => handleFilter(value)}
                                key={key}
                                className={`filter btn-filter ${filter === value ? "active" : ""}`}
                            >
                                {value}
                            </div>
                        ))}
                </div>
            </div>

            <div className="post-list radiothek">
                <div className="container">
                    <div className="row">
                        {posts &&
                            posts.length > 0 &&
                            posts
                                .filter((post) => !filter || post.serie === filter)
                                .map((post, index) => (
                                    <div className="col-12 col-sm-6 col-lg-4 post" key={index}>
                                        <div className="inner" onClick={() => openPopup(post)}>
                                            {post.image && <img src={post.image.sizes["news-list"]} title={post.title} />}
                                            <div className="info">
                                                <img className="play d-inline-block" src="/icon/btn-play.svg" />
                                                <div className="d-inline-block">
                                                    <h3>{post.title}</h3>
                                                    <div>{post.serie}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                    </div>
                </div>
            </div>
        </>
    );
}



export function PostList({ data }) {
		const [posts, setPosts] = useState(null); // Holds all posts
		const [filteredPosts, setFilteredPosts] = useState(null); // Holds filtered posts
		const [tags, setTags] = useState([]); // Holds unique tags
		const [activeTag, setActiveTag] = useState(null); // Tracks the selected tag

		const location = useLocation();
		const content = data;
		const posttypes = content.post_types;
		const number = data.limit || null;
		const pageSlug = location.pathname; // Use the slug for the unique localStorage key
		const storageKey = `activeTag-${pageSlug}`; // Unique localStorage key per page

		var showMore = false;
		if (data.more) {
			showMore = true;
		}

		// Load active tag from localStorage on component mount
		useEffect(() => {
				const savedTag = localStorage.getItem(storageKey);
				if (savedTag) {
						setActiveTag(savedTag);
				}
		}, [storageKey]);

		useEffect(() => {
				const fetchData = async () => {
					try {
						const response = await getPosts(posttypes, number);
						if (response.data) {
								setPosts(response.data);

								const validJsonStr = response.data[0].cats.replace(/'/g, '"');

								const uniqueTags = JSON.parse(validJsonStr);
								setTags(uniqueTags);

								// Apply the saved tag filter, if exists
								const savedTag = localStorage.getItem(storageKey);
								if (savedTag && uniqueTags.includes(savedTag)) {
										const filtered = response.data.filter(post =>
												post.tags && post.tags.some(tag => tag.name === savedTag)
										);
										setFilteredPosts(filtered);
								} else {
										setFilteredPosts(response.data);
								}
						}
					} catch (error) {
							console.error('Error fetching data:', error);
					}
				};

				fetchData();
		}, [number, posttypes, storageKey]);

		// Handle tag filtering
		const handleFilter = (tagName) => {
				setActiveTag(tagName);
				localStorage.setItem(storageKey, tagName); // Save selected filter to localStorage

				if (tagName) {
						const filtered = posts.filter(post =>
								post.tags && post.tags.some(tag => tag.name === tagName)
						);
						setFilteredPosts(filtered);
				} else {
						setFilteredPosts(posts); // Reset to all posts if no tag is selected
				}
		};

		// Check if any post has tags
		const hasTaggedPosts = posts && posts.some(post => post.tags && post.tags.length > 0);

		if (filteredPosts && filteredPosts.length > 0) {

			var newsData = {
					"slogan": filteredPosts[0].title,
					"short": filteredPosts[0].short,
					"slug": filteredPosts[0].slug,
					"date": filteredPosts[0].date,
					"contact": false,
					"image": filteredPosts[0].images,
					"image_banner": filteredPosts[0].image_banner,
					"image_small": false
			}		
		}

		return (
				<>
					{ filteredPosts && filteredPosts.length > 0 && number == null &&
						<Banner data={newsData} />
					}
					
						<div className="post-list">
								{hasTaggedPosts && number == null && ( // Skip filters if no limit is set
									<div className="container filters mb-4">
											<div className="col-12">
													<div
															onClick={() => handleFilter(null)}
															className={`filter btn-filter ${activeTag === null ? 'active' : ''}`}
													>
															Alle
													</div>
													{tags.map((tag, index) => (
															<div
																	key={index}
																	onClick={() => handleFilter(tag)}
																	className={`filter btn-filter ${activeTag === tag ? 'active' : ''}`}
															>
																	{tag}
															</div>
													))}
											</div>
									</div>
								)}

								<div className="container">
										<div className="row">
												{filteredPosts && filteredPosts.length > 0 ? (
														filteredPosts.map((post, index) => (
																<div key={index} className="col-12 col-sm-6 col-lg-4 post">
																		{post.link ? (
																				<a href={post.link} title="Seite öffnen" target="_blank" rel="noreferrer">
																						<div className="inner">
																								<img src={post.image} alt={post.title} />
																								<div className="info">
																										<h3>{post.title}</h3>
																										<div>{post.short}</div>
																								</div>
																								{post.type === 'post' && <span>News</span>}
																								{post.type === 'event' && <span>{post.date}</span>}
																								{post.type === 'job' && <span>Job</span>}
																								{post.type === 'ads' && <span className="small">Werbung</span>}
																								{post.type === 'competition' && <span>Gewinnspiel</span>}
																						</div>
																				</a>
																		) : (
																				<Link key={index} to={'/post/' + post.slug} title={post.title}>
																						<div className="inner">
																								<img src={post.image} alt={post.title} />
																								<div className="info">
																										<h3>{post.title}</h3>
																										<div className="short">
																											{post.short}
																											{ post.tags && post.tags.length > 0 && 
																												<div className="tags">
																							{post.tags.map((tag, index) => (
																									<div className="d-inline-block me-1" key={index}>{tag.name}</div>
																							))}
																												</div>
																											}
																										</div>
																								</div>
																								{ pageSlug == '/' &&
																									<>
																										{post.type === 'post' && <span>News</span>}
																									</>
																							}
																								{ pageSlug == '/' &&
																									<>
																										{post.type === 'event' && <span>Event</span>}
																									</>
																							}
																								{ pageSlug != '/' &&
																									<>
																										{post.type === 'event' && <span>{post.date}</span>}
																									</>
																							}
																								
																								{post.type === 'job' && <span>Job</span>}
																								{post.type === 'ads' && <span className="small">Werbung</span>}
																								{post.type === 'competition' && <span className="small">Gewinnspiel</span>}
																						</div>
																				</Link>
																		)}
																</div>
														))
												) : (
														<p></p>
												)}

												{ showMore &&
													<div className="col-12 post more mt-3">
														 <Link to={'/news'} className="inner">
																<div className="btn-main">Mehr News</div>
														 </Link>
													</div>
												}

										</div>
								</div>
						</div>
				</>
		);
}



export function Voting({ data, i }) {

	const [votings, setVotings] = useState(null);
	const [counter, setCounter] = useState(0);

	const [counterTop20, setCounterTop20] = useState(0);
	const [counterHit, setCounterHit] = useState(0);

	const [unique, setUnique] = useState(null);
	const [top20, setTop20] = useState(null);
	const [hittip, setHittip] = useState(null);

	const location = useLocation();

	useEffect(() => {

		const fetchData = async () => {
			try {
				const response = await getVotings();
				if (response.data) {
					setTop20(response.data.top20);
					setHittip(response.data.hittip);
					setUnique(response.data.unique);
					setVotings(response.data.votes);
					setCounter(response.data.votes.length);

					var top20votes = response.data.top20;
					var counterTop20 = top20votes.filter(item => item.voted === true).length;
					setCounterTop20(counterTop20);

					var hitvotes = response.data.hittip;
					var counterHit = hitvotes.filter(item => item.voted === true).length;
					setCounterHit(counterHit);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, []);

	const voteSong = async (song) => {
		var songVote = song.split('-');

		if (songVote[0] == 'top20') {
			setCounterTop20(prevCounter => {
				const newCounter = prevCounter+1;
				
				if (newCounter < 4) {

					if (songVote[0] === 'top20') {
						setTop20(prevTop20 => {
							const newTop20 = [...prevTop20];
							newTop20[songVote[1]].voted = true;
							return newTop20;
						});
					} else {
						setHittip(prevHittip => {
							const newHittip = [...prevHittip];
							newHittip[songVote[1]].voted = true;
							return newHittip;
						});
					}

					sendVote(unique, song).catch(error => {
						console.error('Error fetching data:', error);
					});
				} else {
					alert('Alle Votes verbraucht!');
				}

				return newCounter;
			});
		} else {
			setCounterHit(prevCounter => {
				const newCounter = prevCounter+1;
				
				if (newCounter < 2) {

					if (songVote[0] === 'top20') {
						setTop20(prevTop20 => {
							const newTop20 = [...prevTop20];
							newTop20[songVote[1]].voted = true;
							return newTop20;
						});
					} else {
						setHittip(prevHittip => {
							const newHittip = [...prevHittip];
							newHittip[songVote[1]].voted = true;
							return newHittip;
						});
					}

					sendVote(unique, song).catch(error => {
						console.error('Error fetching data:', error);
					});
				} else {
					alert('Alle Votes verbraucht!');
				}

				return newCounter;
			});
		}
		
		// setCounter(prevCounter => {
		// 	const newCounter = prevCounter+1;
			
		// 	if (newCounter < 4) {

		// 		if (songVote[0] === 'top20') {
		// 			setTop20(prevTop20 => {
		// 				const newTop20 = [...prevTop20];
		// 				newTop20[songVote[1]].voted = true;
		// 				return newTop20;
		// 			});
		// 		} else {
		// 			setHittip(prevHittip => {
		// 				const newHittip = [...prevHittip];
		// 				newHittip[songVote[1]].voted = true;
		// 				return newHittip;
		// 			});
		// 		}

		// 		sendVote(unique, song).catch(error => {
		// 			console.error('Error fetching data:', error);
		// 		});
		// 	} else {
		// 		alert('Alle Votes verbraucht!');
		// 	}

		// 	return newCounter;
		// });
	};

	
	return (
		<div className="hit-voting">
			<div className="container">
				<div className="row">
					<div className="col-12 col-lg-6 left">
						<h2>Top 20</h2>
						<div className="head">
							<div  className="part number">
								Platz
							</div>
							<div  className="part state">
								
							</div>
							<div  className="part artist-song">
								Interpret/Titel
							</div>
							<div  className="part vote">
								Voting
							</div>
						</div>
						
						<div className="songs">
							{top20 && top20.length > 0 && top20.map((item, index) => {
								return (
									<div key={index} className="song-wrap">
										<div  className="part number">
											{index + 1}
										</div>
										<div  className="part state">
											<img src={'/icon/vote-' + item.status + '.svg'} />
										</div>
										<div  className="part artist-song">
											<img src={item.cover.sizes.thumbnail} />
											<div className="text">
												<div className="artist">{item.artist}</div>
												<div className="song">{item.song}</div>
											</div>
										</div>
										<div  className="part vote">
											{ !item.voted &&
												<img 
													onClick={() => voteSong('top20-' + index)}
													src={'/icon/vote-heart.svg'}
												/>
											}
											{ item.voted &&
												<img 
													src={'/icon/vote-heart-full.svg'}
												/>
											}
										</div>
									</div>
								);
							})}
						</div>
					</div>

					<div className="col-12 col-lg-6 right">
						<h2>Hit-Tipps</h2>
						<div className="head">
							<div  className="part number">
								Platz
							</div>
							<div  className="part state">
								
							</div>
							<div  className="part artist-song">
								Interpret/Titel
							</div>
							<div  className="part vote">
								Voting
							</div>
						</div>
						<div className="songs">
							{hittip && hittip.length > 0 && hittip.map((item, index) => {
								return (
									<div key={index} className="song-wrap">
										<div  className="part number">
											{index + 1}
										</div>
										<div  className="part state">
											<img src={'/icon/vote-' + item.status + '.svg'} />
										</div>
										<div  className="part artist-song">
											<img src={item.cover.sizes.thumbnail} />
											<div className="text">
												<div className="artist">{item.artist}</div>
												<div className="song">{item.song}</div>
											</div>
										</div>
										<div  className="part vote">
											{ !item.voted &&
												<img 
													onClick={() => voteSong('hittip-' + index)}
													src={'/icon/vote-heart.svg'}
												/>
											}
											{ item.voted &&
												<img 
													src={'/icon/vote-heart-full.svg'}
												/>
											}
										</div>
									</div>
								);
							})}
						</div>
					</div>

				</div>
			</div>
		</div>
	);

}

export function Team({ data, i }) {
		const [members, setMembers] = useState(null);
		const [filteredMembers, setFilteredMembers] = useState(null);
		const [categories, setCategories] = useState([]);
		const [activeCategory, setActiveCategory] = useState("Alle"); // Initial active category

		useEffect(() => {
			const fetchData = async () => {
					try {
						const response = await getTeam();
						if (response.data) {
							const membersWithRandomIndex = response.data.map(member => {
								console.log(member)
								const randomIndex = Math.floor(Math.random() * member.questions.length);
								return { ...member, randomIndex };
							});
							setMembers(membersWithRandomIndex);

							// Extract unique categories
							const allCategories = membersWithRandomIndex.flatMap(member => member.categories);
							const uniqueCategories = [...new Set(allCategories)];
							setCategories(uniqueCategories);

							// Load initial category from localStorage or default to "Alle"
							const savedCategory = localStorage.getItem("team-active-category") || "Alle";
							setActiveCategory(savedCategory);

							// Apply initial filter
							if (savedCategory === "Alle") {
								setFilteredMembers(membersWithRandomIndex);
							} else {
								const filtered = membersWithRandomIndex.filter(member =>
										member.categories.includes(savedCategory)
								);
								setFilteredMembers(filtered);
						}
						}
					} catch (error) {
						console.error("Error fetching data:", error);
					}
			};

			fetchData();

			const savedScrollPosition = localStorage.getItem("team-position");
			if (savedScrollPosition) {
				setTimeout(function() {
					window.scrollTo(0, parseInt(savedScrollPosition, 10));
				}, 500)
					
			}
		}, []);

		const handleLinkClick = () => {
				const scrollPosition = window.scrollY;
				localStorage.setItem("team-position", scrollPosition);
		};

		const filterByCategory = (category) => {
				setActiveCategory(category); // Update active category
				localStorage.setItem("team-active-category", category); // Save to localStorage

				if (category === "Alle") {
						setFilteredMembers(members);
				} else {
						const filtered = members.filter(member => member.categories.includes(category));
						setFilteredMembers(filtered);
				}
		};

		return (
				<div className="team-list">

						<div className="container filters mb-4">
							<div className="col-12">	
										<div
											className={activeCategory === "Alle" ? "filter btn-filter mb-1 active" : "filter btn-filter mb-1"}
											onClick={() => filterByCategory("Alle")}
										>
											Alle
										</div>
										{categories.map((category, index) => (
											<div
												key={index}
												className={activeCategory === category ? "filter btn-filter mb-1 active" : "filter btn-filter mb-1"}
												onClick={() => filterByCategory(category)}
											>
												{category}
											</div>
										))}
								</div>
						</div>
						<div className="container">
								
								<div className="row align-items-center">
										{filteredMembers &&
												filteredMembers.length > 0 &&
												filteredMembers.map((member, index) => {
														const { randomIndex } = member;

														return (
																<React.Fragment key={index}>
																		{member.questions.length > 1 && (index + 1 === 3 || index + 1 === 7 || index + 1 === 11 || index + 1 === 15 || index + 1 === 19 || index + 1 === 23 || index + 1 === 27 || index + 1 === 31) && (
																			
																			<div
																				key={index + 99}
																				className="d-none d-sm-block col-6 col-md-6 col-lg-3 fact fact-right"
																			>
																				<div className="q">{member.questions[randomIndex].label}:</div>
																				<div className="a">{member.questions[randomIndex].value}</div>
																				<img src="/icon/arrow-right.svg" alt="" />
																			</div>
																		)}

																		<div key={index} className="col-12 col-sm-6 col-lg-3 member">
																			{ member.id == 1948 &&
																					<Link
																							to="#"
																							title={member.name}
																					>
																							<div className="inner">
																									<img src={member.image} alt={member.name} />
																									<div className="info">
																											<h3>{member.name}</h3>
																									</div>
																							</div>
																					</Link>
																			}

																			{ member.id != 1948 &&
																					<Link
																							to={`/team/${member.slug}`}
																							onClick={handleLinkClick}
																							title={member.name}
																					>
																							<div className="inner">
																									<img src={member.image} alt={member.name} />
																									<div className="info">
																											<h3>{member.name}</h3>
																									</div>
																							</div>
																					</Link>
																			}
																		</div>

																		{member.questions.length > 0 && (index + 1 === 4 || index + 1 === 8 || index + 1 === 12 || index + 1 === 16 || index + 1 === 20 || index + 1 === 24 || index + 1 === 28 || index + 1 === 32) && (
																			<div
																					key={index + 99}
																					className="d-none d-sm-block col-6 col-md-6 col-lg-3 fact fact-left"
																			>
																					<div className="q">{member.questions[randomIndex].label}:</div>
																					<div className="a">{member.questions[randomIndex].value}</div>
																					<img src="/icon/arrow-left.svg" alt="" />
																			</div>
																		)}
																</React.Fragment>
														);
												})}
								</div>
						</div>
				</div>
		);
}





// export function Team({ data, i }) {

// 	const [members, setMembers] = useState(null);

// 	useEffect(() => {
// 		const fetchData = async () => {
// 			try {
// 				const response = await getTeam();
// 				if (response.data) {
// 					const membersWithRandomIndex = response.data.map(member => {
// 						const randomIndex = Math.floor(Math.random() * member.questions.length);
// 						return { ...member, randomIndex };
// 					});
// 					setMembers(membersWithRandomIndex);
// 				}
// 			} catch (error) {
// 				console.error('Error fetching data:', error);
// 			}
// 		};

// 		fetchData();
// 	}, []);
	
// 	return (
// 		<div className="team-list">
// 			<div className="container">
// 				<div className="row align-items-center">
// 					{members && members.length > 0 && members.map((member, index) => {
// 						const { randomIndex } = member;

// 						return (
// 							<>
// 								{ (index+1 === 3 || index+1 === 7 || index+1 === 11  || index+1 === 15  || index+1 === 19 || index+1 === 23  || index+1 === 27  || index+1 === 31) &&
// 									<div key={index + 99 } className="d-none d-sm-block col-6 col-md-6 col-lg-3 fact fact-right">
// 										<div className="q">{member.questions[randomIndex].label}:</div>
// 										<div className="a">{member.questions[randomIndex].value}</div>
// 										<img src="/icon/arrow-right.svg" />
// 									</div>
// 								}

// 								<div key={index} className="col-12 col-sm-6 col-lg-3 member">
// 									<Link to={'/team/' + member.slug} title={member.name}>
// 										<div className="inner">
// 											<img src={member.image} />
// 											<div className="info">
// 												<h3>{member.name}</h3>
// 											</div>
// 										</div>
// 									</Link>
// 								</div>

// 								{ (index+1 === 4 || index+1 === 8 || index+1 === 12 || index+1 === 16 || index+1 === 20 || index+1 === 24 || index+1 === 28 || index+1 === 32) &&
// 									<div key={index + 99 } className="d-none d-sm-block col-6 col-md-6 col-lg-3 fact fact-left">
// 										<div className="q">{member.questions[randomIndex].label}:</div>
// 										<div className="a">{member.questions[randomIndex].value}</div>
// 										<img src="/icon/arrow-left.svg" />
// 									</div>
// 								}
// 							</>
// 						);
// 					})}
// 				</div>
// 			</div>
// 		</div>
// 	);

// }

export function FormContact({ data, i }) {

	let now = new Date();
	let timestamp = now.getTime();

	const [timeOpen, setTimeOpen] = useState(timestamp);
	const [valid, setIsValid] = useState(false);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [city, setCity] = useState('');
	const [subject, setSubject] = useState('');
	const [artist, setArtist] = useState('');
	const [song, setSong] = useState('');
	const [message, setMessage] = useState('');

	function stripTags(input) {
		return input.replace(/<\/?[^>]+(>|$)/g, "");
	}

	const handleForm = (event) => {

		var value = event.target.value;
		value = stripTags(value);

		var field = event.target.name;

		if (field == 'name') {
			setName(value);
		}
		if (field == 'email') {
			setEmail(value);
		}
		if (field == 'phone') {
			setPhone(value);
		}
		if (field == 'city') {
			setCity(value);
		}
		if (field == 'subject') {
			setSubject(value);
		}
		if (field == 'artist') {
			setArtist(value);
		}
		if (field == 'song') {
			setSong(value);
		}
		if (field == 'message') {
			setMessage(value);
		}
		
		if (data.form == 'standard') {
			if (name && email && subject && message) {
				if (name.length > 3 && subject.length > 3 && message.length > 10) {
					const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

					if (emailPattern.test(email)) {
						setIsValid(true);
					} else {
						setIsValid(false);
					}
				} else {
					setIsValid(false);
				}
			} else {
				setIsValid(false);
			}			
		}

		if (data.form == 'music') {
			if (name && email && artist && song && city) {
				if (name.length > 3 && song.length > 3) {
					const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

					if (emailPattern.test(email)) {
						setIsValid(true);
					} else {
						setIsValid(false);
					}
				} else {
					setIsValid(false);
				}
			} else {
				setIsValid(false);
			}			
		}

	};

	const confirmForm = () => {

		setIsValid(false);

		let now = new Date();
		let timestamp = now.getTime();	
		
		let differenceInMilliseconds = timestamp - timeOpen;
		let differenceInSeconds = differenceInMilliseconds / 1000;

		if (differenceInSeconds > 15) {
			sendForm(timestamp);
		}
	}

	const sendForm = async (timestamp) => {
		try {

			const response = await sendFormApi(name, email, phone, city, artist, song, subject, message, timeOpen, timestamp, data.form);

			if (response.data.success == true) {
				setName('');
				setEmail('');
				setPhone('');
				setCity('');
				setSong('');
				setArtist('');
				setSubject('');
				setMessage('');
				alert('Formular erfolgreich versendet!');
				setIsValid(false);
			}

		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	return (
		<div className="contact-form">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="form-inner">
							<h2>{ data.headline }</h2>
							<div className="row">
								<div className="col-12 col-lg-6">
									<div className="form-group">
										<label htmlFor="name">Name*</label>
										<input type="text" className="form-control" value={name} name="name" id="name" onChange={handleForm} required />
									</div>
								</div>
								<div className="col-12 col-lg-6">
									<div className="form-group">
										<label htmlFor="email">E-Mail-Adresse*</label>
										<input type="email" className="form-control" value={email} name="email" id="email" onChange={handleForm} required />
									</div>
								</div>
								<div className="col-12 col-lg-6">
									<div className="form-group">
										<label htmlFor="phone">Telefonnummer</label>
										<input type="text" className="form-control" value={phone} name="phone" id="phone" onChange={handleForm} />
									</div>
								</div>
								<div className="col-12 col-lg-6">
									<div className="form-group">
										<label htmlFor="city">Wohnort*</label>
										<input type="text" className="form-control" value={city} name="city" id="city" onChange={handleForm} />
									</div>
								</div>
								{ data.form == 'standard' &&
									<div className="col-12">
										<div className="form-group">
											<label htmlFor="subject">Betreff*</label>
											<input type="text" className="form-control" value={subject} name="subject" id="subject" onChange={handleForm} />
										</div>
									</div>
								}
								{ data.form == 'music' &&
									<>
										<div className="col-12 col-lg-6">
											<div className="form-group">
												<label htmlFor="artist">Interpret*</label>
												<input type="text" className="form-control" value={artist} name="artist" id="artist" onChange={handleForm} />
											</div>
										</div>
										<div className="col-12 col-lg-6">
											<div className="form-group">
												<label htmlFor="song">Titel*</label>
												<input type="text" className="form-control" value={song} name="song" id="song" onChange={handleForm} />
											</div>
										</div>
									</>
								}
								<div className="col-12">
									<div className="form-group">
										{ data.form == 'standard' &&
											<>
												<label htmlFor="message">Nachricht*</label>
												<textarea className="form-control" id="message" value={message} name="message" onChange={handleForm} rows="6"></textarea>
											</>
										}
										{ data.form == 'music' &&
											<>
												<label htmlFor="message">Grußbotschaft</label>
												<textarea className="form-control" id="message" value={message} name="message" onChange={handleForm} rows="6"></textarea>
											</>
										}
									</div>
								</div>
								<div className="col-12 text-center">
									{ valid == false &&
										<div className="btn-main div">Senden</div>
									}
									{ valid &&
										<button className="btn-main" onClick={confirmForm}>Senden</button>
									}
								</div>
							</div>	
						</div>
					</div>
				</div>
			</div>
		</div>
	);

}

export function FormFree(id) {
	const formId = id.id;

	const [text, setText] = useState(null);
	const [fields, setFields] = useState(null);
	const [button, setButton] = useState(null);
	const [formValues, setFormValues] = useState({});
	const [download, setDownload] = useState(null);
	const [downloadtitle, setDownloadTitle] = useState(null);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getForm(formId);
				if (response.data.data) {
					console.log(response.data.data)
					setText(response.data.data.text);
					setFields(response.data.data.fields);
					setButton(response.data.data.button);
					setDownload(response.data.data.download);
					setDownloadTitle(response.data.data.downloadtitle);
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData();
	}, [formId]);

	function stripTags(input) {
		return input.replace(/<\/?[^>]+(>|$)/g, "");
	}

	const handleForm = (event) => {
		const { name, value, type, checked } = event.target;
		setFormValues((prev) => ({
			...prev,
			[name]: type === "checkbox" ? checked : stripTags(value)
		}));
	};

	const validateForm = () => {
		const validationErrors = {};
		fields.forEach((item) => {
			if (item.required && !formValues[item.text]) {
				validationErrors[item.text] = `${item.text} is required.`;
			}
		});
		setErrors(validationErrors);
		return Object.keys(validationErrors).length === 0;
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (validateForm()) {
			try {

				formValues['form'] = formId

				const response = await sendFreeFormApi(formValues);

				if (response.data.success == true) {
					setFormValues({});
					alert(response.data.message);
				} else {
					alert(response.data.message)
				}

			} catch (error) {
				console.error('Error fetching data:', error);
			}
		} else {
			alert('Bitte füllen Sie alle Pflichtfelder aus!');
		}
	};

	const getGridClasses = (width) => {
		switch (width) {
			case "1":
				return "col-12 col-xl-12";
			case "2":
				return "col-12 col-xl-6";
			case "3":
				return "col-12 col-xl-4";
			default:
				return "col-12";
		}
	};

	return (
		<div className="row free-form">
			{text && (
				<div className="col-12 news-text mb-0">
					<div dangerouslySetInnerHTML={{ __html: text }} />
				</div>
			)}

			{fields && button && (
				<div className="col-12">
					<form className="row" onSubmit={handleSubmit}>
						{fields.map((item, index) => {
							const gridClasses = getGridClasses(item.width);

							switch (item.type) {
								case "text":
									return (
										<div key={index} className={`form-group ${gridClasses}`}>
											<label>
												{item.text}
												{item.required && <span>*</span>}
											</label>
											<input
												type="text"
												name={item.text}
												value={formValues[item.text] || ""}
												onChange={handleForm}
												required={item.required}
												className="form-control"
											/>
											{errors[item.text] && <div className="text-danger">{errors[item.text]}</div>}
										</div>
									);
								case "date":
									return (
										<div key={index} className={`form-group ${gridClasses}`}>
											<label>
												{item.text}
												{item.required && <span>*</span>}
											</label>
											<input
												type="date"
												name={item.text}
												value={formValues[item.text] || ""}
												onChange={handleForm}
												required={item.required}
												className="form-control"
											/>
											{errors[item.text] && <div className="text-danger">{errors[item.text]}</div>}
										</div>
									);
								case "textarea":
									return (
										<div key={index} className={`form-group ${gridClasses}`}>
											<label>
												{item.text}
												{item.required && <span>*</span>}
											</label>
											<textarea
												name={item.text}
												value={formValues[item.text] || ""}
												onChange={handleForm}
												required={item.required}
												className="form-control"
												rows="5"
											/>
											{errors[item.text] && <div className="text-danger">{errors[item.text]}</div>}
										</div>
									);
								case "check":
									return (
										<div key={index} className={`form-group ${gridClasses}`}>
											<label>
												<input
													type="checkbox"
													name={item.text}
													checked={formValues[item.text] || false}
													onChange={handleForm}
													required={item.required}
													className="checkbox"
												/>
												{item.text}
											</label>
											{errors[item.text] && <div className="text-danger">{errors[item.text]}</div>}
										</div>
									);
								case "select":
									const options = item.selection.split("\n");
									return (
										<div key={index} className={`form-group ${gridClasses}`}>
											<label>
												{item.text}
												{item.required && <span>*</span>}
											</label>
											<select
												name={item.text}
												value={formValues[item.text] || ""}
												onChange={handleForm}
												required={item.required}
												className="form-control"
											>
												<option value="">Please select</option>
												{options.map((option, idx) => (
													<option key={idx} value={option}>
														{option}
													</option>
												))}
											</select>
											{errors[item.text] && <div className="text-danger">{errors[item.text]}</div>}
										</div>
									);
								default:
									return null;
							}
						})}

						<div className="form-group col-12">
							<button type="submit" className="btn btn-main">
								{button}
							</button>
						</div>
					</form>
				</div>
			)}

			{download && downloadtitle && (
				<div className="col-12 news-text mb-0">
					<p><a href={download} target="_blank" download>{downloadtitle}</a></p>
				</div>
			)}

		</div>
	);
}


export function SearchSongs() {
	const [date, setDate] = useState(null);
	const [hour, setHour] = useState(null);
	const [minute, setMinute] = useState(null);
	const [dateSet, setDateSet] = useState(null);
	const [timeSet, setTimeSet] = useState(null);

	const location = useLocation();

	useEffect(() => {
		const getQueryParameters = () => {
			const queryParams = new URLSearchParams(location.search);
			const dateParam = queryParams.get('d');
			const timeParam = queryParams.get('t');
			if (dateParam && timeParam) {
				setDateSet(dateParam);
				const [hourParam, minuteParam] = timeParam.split(':');
				setHour(hourParam);
				setMinute(minuteParam);
			}
		};

		getQueryParameters();
	}, [location.search]);

	var today = new Date();
	var yesterday = new Date(today);
	yesterday.setDate(today.getDate() - 1);
	var dayBeforeYesterday = new Date(today);
	dayBeforeYesterday.setDate(today.getDate() - 2);

	today = formatDate(today);
	yesterday = formatDate(yesterday);
	dayBeforeYesterday = formatDate(dayBeforeYesterday);

	function setCurrentTime() {
		const now = new Date();
		let hours = now.getHours();
		let minutes = now.getMinutes();

		if (hours < 10) hours = '0' + hours;
		if (minutes < 10) minutes = '0' + minutes;

		return { hours, minutes };
	}

	const currentTime = setCurrentTime();

	function formatDate(date) {
		let d = new Date(date);
		let month = '' + (d.getMonth() + 1);
		let day = '' + d.getDate();
		let year = d.getFullYear();
		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;
		return [year, month, day].join('-');
	}

	useEffect(() => {
		if (dateSet) {
			setDate(dateSet);
		} else {
			setDate(today);
		}

		if (hour === null && minute === null) {
			setHour(currentTime.hours);
			setMinute(currentTime.minutes);
		}
	}, [dateSet, hour, minute]);

	const handleDateChange = (event) => {
		setDate(event.target.value);
	};

	const handleHourChange = (event) => {
		setHour(event.target.value);
	};

	const handleMinuteChange = (event) => {
		setMinute(event.target.value);
	};

	return (
		<div className="row align-items-center search-song">
			<div className="col-4 left">Titel<br />Suche</div>
			<div className="col-4 middle">
				{date && (
					<select className="day" name="day" onChange={handleDateChange} value={date}>
						<option value={today}>Heute</option>
						<option value={yesterday}>Gestern</option>
						<option value={dayBeforeYesterday}>Vorgestern</option>
					</select>
				)}
				<div className="time-selects">
					<select name="hour" onChange={handleHourChange} value={hour}>
						{Array.from({ length: 24 }, (_, i) => (
							<option key={i} value={i.toString().padStart(2, '0')}>
								{i.toString().padStart(2, '0')}
							</option>
						))}
					</select>
					<span>:</span>
					<select name="minute" onChange={handleMinuteChange} value={minute}>
						{Array.from({ length: 60 }, (_, i) => (
							<option key={i} value={i.toString().padStart(2, '0')}>
								{i.toString().padStart(2, '0')}
							</option>
						))}
					</select>
				</div>
			</div>
			<div className="col-4 p-0 p-lg-2 right-side">
				<Link to={`/titelabfrage/?d=${date}&t=${hour}:${minute}`}>Suchen</Link>
			</div>
		</div>
	);
}


// export function BannerStart({ data, isPlaying, toggleIsPlaying, currentPlaying }) {

// 	const [frequencies, setFrequencies] = useState(null);
// 	const [currentFrequencyIndex, setCurrentFrequencyIndex] = useState(0);

// 	if (typeof currentPlaying !== 'object') {
// 		currentPlaying = JSON.parse(currentPlaying);
// 	}

// 	var content = data;

// 	var slogan = data.slogan;
// 	var imageMd = data.image.sizes['banner-start-sm'];
// 	var imageLg = data.image.sizes['banner-start'];

// 	useEffect(() => {
// 		const fetchData = async () => {
// 			try {
// 				const response = await getFrequencies();
// 				if (response.data.data) {
// 					setFrequencies(response.data.data);
// 				}
// 			} catch (error) {
// 				console.error('Error fetching data:', error);
// 			}
// 		};

// 		fetchData();
// 	}, []); 

// 	useEffect(() => {
// 		if (frequencies && frequencies.length > 0) {
// 			const intervalId = setInterval(() => {
// 				setCurrentFrequencyIndex((prevIndex) => (prevIndex + 1) % frequencies.length);
// 			}, 5000);

// 			return () => clearInterval(intervalId);
// 		}
// 	}, [frequencies]);

// 	const togglePlayPause = () => {
// 	    toggleIsPlaying();
// 	    window.scrollBy({
// 	        top: window.innerHeight, // Scroll down 100vh
// 	        behavior: 'smooth', // Adds smooth scrolling effect
// 	    });
// 	};

	
// 	return (
// 		<div className="banner_start start_detail">
// 			<div className="container picture">
// 				<picture>
// 					<source media="(max-width: 767px)" srcSet={imageMd} />
// 					<source media="(max-width: 768px)" srcSet={imageLg} />
// 					<img src={imageLg} alt="Radio Grün Weiß" />
// 				</picture>

// 				{ (content.name || content.program) &&
// 					<div className="on-air">
// 						<div className="row align-items-center">
// 							<div className="col-12 col-xl-auto title">ON AIR</div>
// 							<div className="col-12 col-xl-auto pb-1 pb-xl-0">
// 								{ content.name &&
// 									<div className="name">{content.name}</div>
// 								}
// 								{ content.program.text &&
// 									<div className="program">{content.program.text}</div>
// 								}
// 							</div>
// 						</div>
// 					</div>
// 				}
	
// 			</div>
// 			<div className="overlay">
// 				<div className="container">
// 						<div className="col-12 col-lg-6 offset-lg-6 p-0">
// 							<div className="row">
// 								<div className="col-12 order-lg-2">
// 									<h1 className="slogan ">{slogan}</h1>
// 								</div>
// 								<div className="col-12 order-lg-1">
// 									<div className="player">

// 										<div className="row player-top">
// 											<div className="col-8">
// 												<img className="cover" src={ currentPlaying.cover } />
// 											</div>
// 											<div className="col-4 text-end images">
// 												<img className="live d-inline-block" src="/icon/live.png" />
// 												<div className="play d-inline-block">
// 													{ !isPlaying &&
// 														<img onClick={togglePlayPause} src="/icon/btn-play.svg" />
// 													}
// 													{ isPlaying &&
// 														<img onClick={togglePlayPause} src="/icon/btn-pause.svg" />
// 													}
// 													</div>
// 											</div>
// 											<div className="col-12 info">
// 												<span className="artist">{ currentPlaying.artist }</span>
// 												<span className="track">{ currentPlaying.title }</span>
// 											</div>
// 										</div>
// 										<div className="player-bottom">
// 											<SearchSongs />
// 										</div>
// 									</div>
// 								</div>
// 								<div className="col-12 order-lg-3">
// 									<div className="frequencies">
// 										{ frequencies !== null &&
// 											<div className="frequency">
// 												<img src="/icon/icon-location.svg" />
// 												<div className="city">{ frequencies[currentFrequencyIndex].title }</div>
// 												<div className="mhz">{ frequencies[currentFrequencyIndex].mhz } <span>MHz</span></div>
// 											</div>
// 										}
// 										{ frequencies == null &&
// 											<div className="frequency">
// 												<img src="/icon/icon-location.svg" />
// 												<div className="city">-</div>
// 												<div className="mhz">-</div>
// 											</div>
// 										}
// 										<Link to="/empfang" title="Alle Frequenzen/Empfang">Alle Frequenzen<span className="d-lg-none d-xl-inline-block">/Empfang</span></Link>
// 									</div>
									
// 								</div>
// 							</div>
// 						</div>
// 				</div>
// 			</div>		
// 		</div>
// 	);
// }

export function BannerStart({ data, isPlaying, toggleIsPlaying, currentPlaying, coverFlow }) {

	const [frequencies, setFrequencies] = useState(null);
	const [currentFrequencyIndex, setCurrentFrequencyIndex] = useState(0);

	const [songLastLast, setSongLastLast] = useState(null);
	const [songLast, setSongLast] = useState(null);
	const [songNext, setSongNext] = useState(null);
	const [songNextNext, setSongNextNext] = useState(null);
	const [activeCover, setActiveCover] = useState('current');

	if (typeof currentPlaying !== 'object') {
		currentPlaying = JSON.parse(currentPlaying);
	}

	var content = data;

	console.log(content.program.text)

	var slogan = data.slogan;
	var imageMd = data.image.sizes['banner-start-sm'];
	var imageLg = data.image.sizes['banner-start'];

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getFrequencies();
				if (response.data.data) {
					setFrequencies(response.data.data);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, []); 

		useEffect(() => {
				const intervalId = setInterval(() => {
						const currLive = currentPlaying.length;
						const currSaved = localStorage.getItem('curr');

						if (currSaved !== currLive) {
								setActiveCover('current');
						}

						localStorage.setItem('curr', currLive);
				}, 1000);

				return () => clearInterval(intervalId);
		}, [currentPlaying]);

	useEffect(() => {
		if (frequencies && frequencies.length > 0) {
			const intervalId = setInterval(() => {
				setCurrentFrequencyIndex((prevIndex) => (prevIndex + 1) % frequencies.length);
			}, 5000);

			return () => clearInterval(intervalId);
		}
	}, [frequencies]);


	useEffect(() => {

		if (coverFlow) {

			if (Array.isArray(coverFlow)) {
				const playingIndex = coverFlow.findIndex(item => item.sequence === "playing");

				var lastIndex = playingIndex-1;
				setSongLast(coverFlow[lastIndex]);

				var lastLastIndex = playingIndex-2;
				if (lastLastIndex > -1) {
					setSongLastLast(coverFlow[lastLastIndex]);
				}

				var nextIndex = playingIndex+1;
				setSongNext(coverFlow[nextIndex]);

				var nextNextIndex = playingIndex+2;
				if (nextNextIndex == coverFlow.length -1) {
					setSongNextNext(coverFlow[nextNextIndex]);
				}
			}
		}

	}, [coverFlow]);

const togglePlayPause = () => {
		toggleIsPlaying();

		// Get all elements with the class 'section'
		const sections = document.querySelectorAll('.section');

		// Find the currently visible section
		const currentSection = Array.from(sections).find((section, index) => {
				const rect = section.getBoundingClientRect();
				return rect.top <= window.innerHeight && rect.bottom >= 0;
		});

		// If a current section is found, scroll to the next one with an offset
		if (currentSection) {
				const nextSection = currentSection.nextElementSibling;

				if (nextSection && nextSection.classList.contains('section')) {
						// Scroll the next section into view, but offset by -100px
						window.scrollTo({
								top: nextSection.offsetTop - 70, // Offset the scroll by 100px
								behavior: 'smooth',               // Smooth scroll effect
						});
				}
		}
};



	const showLast = () => {
		setActiveCover('last');
	}
	const showLastLast = () => {
		setActiveCover('lastlast');
	}
	const showNext = () => {
		setActiveCover('next');
	}
	const showNextNext = () => {
		setActiveCover('nextnext');
	}
	const showCurrent = () => {
		setActiveCover('current');
	}
	
	return (
		<div className="banner_start start_detail">
			<div className="container picture">
				<picture>
					<source media="(max-width: 767px)" srcSet={imageMd} />
					<source media="(max-width: 768px)" srcSet={imageLg} />
					<img src={imageLg} alt="Radio Grün Weiß" />
				</picture>

				{ (content.name || content.program) &&
					<div className="on-air">
						<div className="row align-items-center">
							<div className="col-12 col-xl-auto title">ON AIR</div>
							<div className="col-12 col-xl-auto pb-1 pb-xl-0">
								{ content.name &&
									<div className="name">{content.name}</div>
								}
								{ content.program.text &&
									<div className="program">{content.program.text}</div>
								}
							</div>
						</div>
					</div>
				}
	
			</div>
			<div className="overlay">
				<div className="container">
						<div className="col-12 col-lg-6 offset-lg-6 p-0">
							<div className="row">
								<div className="col-12 order-lg-2">
									<h1 className="slogan ">{slogan}</h1>
								</div>
								<div className="col-12 order-lg-1">
									<div className="player">
											<div className="control-outer">

												{activeCover === "lastlast" && (
													<div className="control">
														<div className="control-next" onClick={showLast}>
															<img src="/icon/player-right.png" alt="Zuletzt" />
														</div>
														<div className="control">Davor gespielt</div>
													</div>
												)}

												{activeCover === "last" && (
													<div className="control">
														<div className="control-last" onClick={showLastLast}>
															<img src="/icon/player-left.png" alt="Zuletzt" />
														</div>
														<div className="control">Davor gespielt</div>
														<div className="control-next" onClick={showCurrent}>
															<img src="/icon/player-right.png" alt="Aktuell" />
														</div>
													</div>
												)}

												{activeCover === "current" && (
													<div className="control">
														<div className="control-last" onClick={showLast}>
															<img src="/icon/player-left.png" alt="Zuletzt" />
														</div>
															{ songNext &&
															<div className="control-next" onClick={showNext}>
																<img src="/icon/player-right.png" alt="Demnächst" />
															</div>
														}
														<div className="control">Jetzt Live</div>
													</div>
												)}

												{activeCover === "next" && (
													<div className="control">
														<div className="control-last" onClick={showCurrent}>
															<img src="/icon/player-left.png" alt="Aktuell" />
														</div>
															{ songNextNext &&
															<div className="control-next" onClick={showNextNext}>
																<img src="/icon/player-right.png" alt="Demnächst" />
															</div>
														}
														<div className="control">Demnächst</div>
													</div>
												)}

												{activeCover === "nextnext" && (
													<div className="control">
														<div className="control-last" onClick={showNext}>
															<img src="/icon/player-left.png" alt="Aktuell" />
														</div>
														<div className="control">Demnächst</div>
													</div>
												)}
											</div>

										<div className={`row player-top ${activeCover}`}>
											<div className="col-12 align-items-center player-top-inner">
												{ songLastLast &&
													<div className="coverflow song-last-last">
														<div className="cover">
															<img src={ songLastLast.cover } />
															<div className="play d-inline-block">
																	{ !isPlaying &&
																		<img onClick={togglePlayPause} src="/icon/btn-play.svg" />
																	}
																	{ isPlaying &&
																		<img onClick={togglePlayPause} src="/icon/btn-pause.svg" />
																	}
															</div>
														</div>
														<div className="col-12 info">
															<span className="artist">{ songLastLast.artist }</span>
															<span className="track">{ songLastLast.title }</span>
														</div>
													</div>
												}
												{ songLast &&
													<div className="coverflow song-last">
														<div className="cover">
															<img src={ songLast.cover } />
															<div className="play d-inline-block">
																	{ !isPlaying &&
																		<img onClick={togglePlayPause} src="/icon/btn-play.svg" />
																	}
																	{ isPlaying &&
																		<img onClick={togglePlayPause} src="/icon/btn-pause.svg" />
																	}
															</div>
														</div>
														<div className="col-12 info">
															<span className="artist">{ songLast.artist }</span>
															<span className="track">{ songLast.title }</span>
														</div>
													</div>
												}
												<div className="coverflow song-current">
													<div className="cover">
														<img src={ currentPlaying.cover } />
														<div className="play d-inline-block">
																{ !isPlaying &&
																	<img onClick={togglePlayPause} src="/icon/btn-play.svg" />
																}
																{ isPlaying &&
																	<img onClick={togglePlayPause} src="/icon/btn-pause.svg" />
																}
														</div>
													</div>
													<div className="col-12 info">
														<span className="artist">{ currentPlaying.artist }</span>
														<span className="track">{ currentPlaying.title }</span>
													</div>
												</div>
												{ songNext &&
													<div className="coverflow song-next">
														<div className="cover">
															<img src={ songNext.cover } />
															<div className="play d-inline-block">
																	{ !isPlaying &&
																		<img onClick={togglePlayPause} src="/icon/btn-play.svg" />
																	}
																	{ isPlaying &&
																		<img onClick={togglePlayPause} src="/icon/btn-pause.svg" />
																	}
															</div>
														</div>
														<div className="col-12 info">
															<span className="artist">{ songNext.artist }</span>
															<span className="track">{ songNext.title }</span>
														</div>
													</div>
												}
												{ songNextNext &&
													<div className="coverflow song-next-next">
														<div className="cover">
															<img src={ songNextNext.cover } />
															<div className="play d-inline-block">
																	{ !isPlaying &&
																		<img onClick={togglePlayPause} src="/icon/btn-play.svg" />
																	}
																	{ isPlaying &&
																		<img onClick={togglePlayPause} src="/icon/btn-pause.svg" />
																	}
															</div>
														</div>
														<div className="col-12 info">
															<span className="artist">{ songNextNext.artist }</span>
															<span className="track">{ songNextNext.title }</span>
														</div>
													</div>
												}
											</div>
										</div>
										<div className="player-bottom">
											<SearchSongs />
										</div>
									</div>
								</div>
								<div className="col-12 order-lg-3">
									<div className="frequencies">
										{ frequencies !== null &&
											<div className="frequency">
												<img src="/icon/icon-location.svg" />
												<div className="city">{ frequencies[currentFrequencyIndex].title }</div>
												<div className="mhz">{ frequencies[currentFrequencyIndex].mhz } <span>MHz</span></div>
											</div>
										}
										{ frequencies == null &&
											<div className="frequency">
												<img src="/icon/icon-location.svg" />
												<div className="city">-</div>
												<div className="mhz">-</div>
											</div>
										}
										<Link to="/empfang" title="Alle Frequenzen/Empfang">Alle Frequenzen<span className="d-lg-none d-xl-inline-block">/Empfang</span></Link>
									</div>
									
								</div>
							</div>
						</div>
				</div>
			</div>		
		</div>
	);
}

export function BannerStyria({ data }) {

	var content = data;
	var slogan = data.slogan;
	var text = data.text;
	var imageMd = data.image.sizes['banner-start-sm'];
	var imageLg = data.image.sizes['banner-start'];

	return (
		<div className="banner_start banner_styria">
			<div className="container picture">
				<picture>
					<source media="(max-width: 767px)" srcSet={imageMd} />
					<source media="(max-width: 768px)" srcSet={imageLg} />
					<img src={imageLg} alt="Radio Grün Weiß" />
				</picture>
			</div>
			<div className="overlay">
				<div className="container h-100">
					<div className="row h-100 align-items-center">
						<div className="col-12 col-lg-6 p-0 h-100 position-relative">
						<h1 className="slogan" dangerouslySetInnerHTML={{ __html: slogan }} />
						</div>
						<div className="col-12 col-lg-6 p-0">
							<LiveLocations  />
							<p className="subline">{text}</p>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	);
}

export function Banner({ data }) {

	var content = data;
	var slogan = data.slogan;

	if (content.image_small) {
		var imageMd = data.image_small.sizes['banner-start'];
	} else {
		var imageMd = data.image.sizes['banner-start-sm'];
	}

	var imageLg = data.image.sizes['banner-start'];

	if (content.image_banner) {
		var imageMd = data.image_banner.sizes['image-full'];
		var imageLg = data.image_banner.sizes['image-full'];
	}

	var title = document.getElementsByTagName("title")[0].innerHTML;
	if (title.includes("News")) {
		var newTitle = 'News';
	}
	if (title.includes("Events")) {
		var newTitle = 'Events';
	}
	if (title.includes("Gewinnspiele")) {
		var newTitle = 'Gewinnspiele';
	}

	return (
		<>
		<div className="banner_start banner_team">
			<div className="container picture">
				{ !content.image_small &&
					<picture>
						<source media="(max-width: 767px)" srcSet={imageMd} />
						<source media="(min-width: 768px)" srcSet={imageLg} />
						<img src={imageLg} alt="Radio Grün Weiß" />
					</picture>
				}	
				{ content.image_small &&
					<Link target="_blank" className="maps-link" to='https://www.google.com/maps/place/Radio+Gr%C3%BCn+Wei%C3%9F/@47.381287,15.095692,16z/data=!4m5!3m4!1s0x0:0xec51e3b36ee6d72!8m2!3d47.3812877!4d15.0956947?hl=de-DE'>
						<picture>
							<source media="(max-width: 767px)" srcSet={imageMd} />
							<source media="(min-width: 768px)" srcSet={imageLg} />
							<img src={imageLg} alt="Radio Grün Weiß" />
						</picture>
					</Link>
				}	
			</div>
			<div className="overlay">
				<div className="container h-100">

					{ content.image_small &&
						<div className="row h-100">
							<div className="col-12 col-lg-6 p-0">
								<Link target="_blank" className="maps-link" to='https://www.google.com/maps/place/Radio+Gr%C3%BCn+Wei%C3%9F/@47.381287,15.095692,16z/data=!4m5!3m4!1s0x0:0xec51e3b36ee6d72!8m2!3d47.3812877!4d15.0956947?hl=de-DE'>
								</Link>
							</div>
							<div className="col-12 col-lg-6">
								<div className="row">
									<div className="col-12">
										<h1 className="slogan ">{slogan}</h1>
											<div className="infobox contact">
												<div className="infos">
													<div className="col-12 info mb-2">
														<div className="q">Radio Grün Weiß GmbH</div>
														<div className="a">Langgasse 6, A-8700 Leoben</div>
													</div>
													<img src="/icon/phone.svg" />
													<div className="col-12 info mb-0">
														<div className="row">
															<div className="col-5 q">Hörerservice:</div>
															<div className="col-7 a text-end"><a href="tel:+43384243000">+43 3842 43000</a></div>
														</div>
													</div>
													<div className="col-12 info mb-0">
														<div className="row">
														<div className="col-5 q">Studio:</div>
														<div className="col-7 a text-end"><a href="tel:+4338424300043">+43 3842 43000 43</a></div>
														</div>
													</div>
													<div className="col-12 info mb-0">
														<div className="row">
														<div className="col-5 q">WhatsApp:</div>
														<div className="col-7 a text-end"><a href="https://wa.me/+436642222994">+43 664 2222 994</a></div>
														</div>
													</div>
													<div className="col-12 info mb-0">
														<div className="row">
														<div className="col-5 q">Redaktion:</div>
														<div className="col-7 a text-end"><a href="tel:+4338424300024">+43 3842 43000 24</a></div>
														</div>
													</div> 
													<img src="/icon/mail.svg" />
													<div className="col-12 info mb-0">
														<div className="row">
														<div className="col-4 q">E-Mail:</div>
														<div className="col-8 a text-end"><a href="mailto:radio@gruen-weiss.at">radio@gruen-weiss.at</a></div>
														</div>
													</div> 
												</div>
											</div>

									</div>
								</div>
							</div>
						</div>
					}

					{ !content.image_small &&
						<div className="col-12 col-lg-6 offset-lg-6 p-0">
							<div className="row">
								<div className="col-12">
									{ !newTitle &&
										<h1 className="slogan ">{slogan}</h1>
									}
									{ newTitle &&
										<h1 className="slogan ">{newTitle}</h1>
									}									
									{ data.short &&
										<div className="infobox contact">
											<div className="infos">
												<div className="col-12 info">

													<div className="q">{slogan}</div>
													{ data.date &&
														<div className="q mt-1">{data.date}</div>
													}
													<div className="a mt-2">{data.short}</div>


													{ data.slug &&
														<Link className="btn btn-main mt-3" to={'/post/' + data.slug} title={data.slogan}>
															Weiterlesen
														</Link>
													}

												</div>

											</div>
										</div>
									}
								</div>
							</div>
						</div>
					}
				</div>
				<a href="#down" className="scrolldown"><img src="/icon/arrow-down.svg" /></a>
			</div>
			<div id="down"></div>
		</div>
		
		</>
	);
}



// export function LiveLocations() {
// 	const [locations, setLocations] = useState(null);

// 	const fetchDataAndMarkers = async () => {
// 		try {
// 			const response = await getSentPositions();
// 			if (response) {
// 				setLocations(response.data.live_data.map(entry => entry.position));
// 				const currentTimeInMilliseconds = Date.now();

// 				updateMarkers(response.data.live_data.filter(entry => (currentTimeInMilliseconds - entry.timestamp * 1000) <= 10000).map(entry => entry.position));
// 			}
// 		} catch (error) {
// 			console.error('Error fetching data:', error);
// 		}
// 	};

// 	useEffect(() => {
// 		fetchDataAndMarkers();
// 		const intervalId = setInterval(fetchDataAndMarkers, 10000);

// 		return () => {
// 			clearInterval(intervalId);
// 		};
// 	}, []); 

// 	const updateMarkers = (locations) => {
// 		const mapContainer = document.getElementById("map-container");
// 		const markersContainer = document.getElementById("markers-container");

// 		markersContainer.innerHTML = "";

// 		locations &&
// 			locations.forEach((position, index) => {
// 				const [latitude, longitude] = position.split(",").map(Number);

// 				const marker = document.createElement("div");
// 				marker.className = "marker";

// 				const MIN_LONGITUDE = 13.561724;
// 				const MAX_LONGITUDE = 16.073340;
// 				const MIN_LATITUDE = 46.624794;
// 				const MAX_LATITUDE = 47.829133;

// 				const normalizedLongitude = (longitude - MIN_LONGITUDE) / (MAX_LONGITUDE - MIN_LONGITUDE);
// 				const normalizedLatitude = (latitude - MIN_LATITUDE) / (MAX_LATITUDE - MIN_LATITUDE);

// 				const markerXPercent = normalizedLongitude * 100;
// 				const markerYPercent = (1 - normalizedLatitude) * 100;

// 				const timeout = (10 * 1000) / locations.length * index;

// 				setTimeout(() => {
// 					marker.style.left = `${markerXPercent}%`;
// 					marker.style.top = `${markerYPercent}%`;
// 					markersContainer.appendChild(marker);
// 				}, timeout);
// 			});
// 	};

// 	return (
// 		<div>
// 			<div id="map-container">
// 				<img src="https://backend.gruen-weiss.at/wp-content/uploads/styria.png" alt="Styria Map" id="styria-map" />
// 				<div id="markers-container"></div>
// 			</div>
// 		</div>
// 	);
// }



