import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { SearchSongs } from './Elements.js'

const AudioPlayer = ({ audioUrl, isPlaying, toggleIsPlaying, currentPlaying, onAudioEnd }) => {
    const audioRef = useRef(null);
    const [active, setActive] = useState(false);
    const [hidePlayer, setHidePlayer] = useState(false);
    const [audioDuration, setAudioDuration] = useState(null);
    const [currentPosition, setCurrentPosition] = useState(null);
    const [currentPositionLive, setCurrentPositionLive] = useState(null);
    const [isHovered, setIsHovered] = useState(false);
    const liveRef = useRef(null);
    const artistRef = useRef(null);
    const trackRef = useRef(null);
    const [addExtraSpan, setAddExtraSpan] = useState(false);

    const { pathname } = useLocation();

    if (typeof currentPlaying !== 'object') {
        currentPlaying = JSON.parse(currentPlaying);
    }

    const togglePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
            if (audioUrl == 'https://live.gruen-weiss.at/rgw.mp3') {
                audioRef.current.src = '';
                audioRef.current.currentTime = 0;
            }
        } else {
            if (audioUrl == 'https://live.gruen-weiss.at/rgw.mp3') {
                audioRef.current.src = audioUrl;
                audioRef.current.currentTime = 0;
            }
            audioRef.current.play();
        }
        toggleIsPlaying();
    };


    const handleScroll = () => {
        const scrollY = window.scrollY;
        if (scrollY > 150) {
            setHidePlayer(false);
        } else {
            setHidePlayer(true);
        }
    };

    useEffect(() => {
        const checkWidths = () => {
            if (liveRef.current && artistRef.current && trackRef.current) {
                const liveWidth = liveRef.current.getBoundingClientRect().width;
                const artistWidth = artistRef.current.getBoundingClientRect().width;
                const titleWidth = trackRef.current.getBoundingClientRect().width;

                setAddExtraSpan(liveWidth < (artistWidth + titleWidth));
            }
        };

        // Check on mount and resize
        checkWidths();
        window.addEventListener("resize", checkWidths);
        return () => {
            window.removeEventListener("resize", checkWidths);
        };
    }, []);

    useEffect(() => {
        if (pathname === '/') {
            setHidePlayer(true);
            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        } else {
            setHidePlayer(false);
        }
    }, [pathname]);

    useEffect(() => {
        if (isPlaying) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
    }, [isPlaying]);

    useEffect(() => {
        const onDurationChange = () => {
            setAudioDuration(audioRef.current.duration);
        };

        if (audioRef.current) {
            audioRef.current.addEventListener('durationchange', onDurationChange);

            return () => {
                audioRef.current.removeEventListener('durationchange', onDurationChange);
            };
        }
    }, [audioUrl]);


    const switchActive = () => {
        if (active) {
            setActive(false)
        } else {
            setActive(true)
        }
    };

    const handlePlaybackEnd = () => {
        if (currentPlaying && currentPlaying.info) {
            if (typeof onAudioEnd === 'function') {
                onAudioEnd();
            }
        }
    };

    const handleTimeUpdate = () => {
        setCurrentPosition(audioRef.current.currentTime);
    };

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.addEventListener('ended', handlePlaybackEnd);
            audioRef.current.addEventListener('timeupdate', handleTimeUpdate);

            return () => {
                audioRef.current.removeEventListener('ended', handlePlaybackEnd);
                audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
            };
        }
    }, [currentPlaying.info]);

    const jumpToPosition = (position) => {
        if (audioRef.current) {
            audioRef.current.currentTime = position;
        }
    };

    const handleProgressBarClick = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        const width = rect.width;
        const percentClicked = offsetX / width;
        const newPosition = percentClicked * audioDuration;
        if (audioRef.current) {
            audioRef.current.currentTime = newPosition;
        }
    };

    useEffect(() => {
        if (!currentPlaying.info) {
            const calculateCurrentPosition = () => {
                const startTime = new Date(currentPlaying.start).getTime();
                var currentTime = new Date().getTime();

                currentTime = currentTime - 10 * 1000;

                const elapsedSeconds = (currentTime - startTime) / 1000;
                setCurrentPositionLive(elapsedSeconds / currentPlaying.length)
                
            };

            calculateCurrentPosition();
            const intervalId = setInterval(calculateCurrentPosition, 1000);

            return () => clearInterval(intervalId);
        }
    }, [currentPlaying]);

    return (
        <div id="player" className={`sticky-bottom ${hidePlayer ? 'hidden' : ''} ${active ? 'active' : ''}`}>
            <audio
                ref={audioRef}
                src={audioUrl}
            />
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-10 col-lg-6 col-xl-5">
                        <div  onClick={togglePlayPause} className="play d-inline-block">
                            {!isPlaying &&
                                <img src="/icon/btn-play.svg" />
                            }
                            {isPlaying &&
                                <img src="/icon/btn-pause.svg" />
                            }
                        </div>
                        <img className="cover" src={currentPlaying.cover} />
                        <div className="live d-inline-block" ref={liveRef}>
                            {!currentPlaying.info &&
                                <div className="hl">
                                    Jetzt live
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25.071" height="21.575" viewBox="0 0 25.071 21.575"><g id="Gruppe_109" data-name="Gruppe 109" transform="translate(-867 -4513)"><line id="Linie_31" data-name="Linie 31" y2="10.889" transform="translate(868.5 4518.343)" fill="none" stroke="#3cad6c" strokeLinecap="round" strokeWidth="3"/><line id="Linie_33" data-name="Linie 33" y2="10.889" transform="translate(879.535 4518.343)" fill="none" stroke="#3cad6c" strokeLinecap="round" strokeWidth="3"/><line id="Linie_34" data-name="Linie 34" y2="10.889" transform="translate(890.571 4518.343)" fill="none" stroke="#3cad6c" strokeLinecap="round" strokeWidth="3"/><line id="Linie_32" data-name="Linie 32" y2="18.575" transform="translate(874.018 4514.5)" fill="none" stroke="#3cad6c" strokeLinecap="round" strokeWidth="3"/><line id="Linie_35" data-name="Linie 35" y2="18.575" transform="translate(885.053 4514.5)" fill="none" stroke="#3cad6c" strokeLinecap="round" strokeWidth="3"/></g></svg>
                                </div>
                            }
                            {currentPlaying.info &&
                                <div className="hl">
                                    Du hörst
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25.071" height="21.575" viewBox="0 0 25.071 21.575"><g id="Gruppe_109" data-name="Gruppe 109" transform="translate(-867 -4513)"><line id="Linie_31" data-name="Linie 31" y2="10.889" transform="translate(868.5 4518.343)" fill="none" stroke="#3cad6c" strokeLinecap="round" strokeWidth="3"/><line id="Linie_33" data-name="Linie 33" y2="10.889" transform="translate(879.535 4518.343)" fill="none" stroke="#3cad6c" strokeLinecap="round" strokeWidth="3"/><line id="Linie_34" data-name="Linie 34" y2="10.889" transform="translate(890.571 4518.343)" fill="none" stroke="#3cad6c" strokeLinecap="round" strokeWidth="3"/><line id="Linie_32" data-name="Linie 32" y2="18.575" transform="translate(874.018 4514.5)" fill="none" stroke="#3cad6c" strokeLinecap="round" strokeWidth="3"/><line id="Linie_35" data-name="Linie 35" y2="18.575" transform="translate(885.053 4514.5)" fill="none" stroke="#3cad6c" strokeLinecap="round" strokeWidth="3"/></g></svg>
                                    { currentPlaying.info &&
                                        <span className="btn" onClick={handlePlaybackEnd}>Zum Live Radio</span>
                                    }   
                                </div>
                            }
                            { currentPlaying.info &&
                                <span className="btn btn-live d-lg-none" onClick={handlePlaybackEnd}>Zum Live Radio</span>
                            } 
                            <div
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                
                            >
                                {isHovered ? (
                                    <marquee duplicated="true" className="artist-track">
                                        <span className="track">{currentPlaying.artist}</span>
                                        <span className="artist"><span className="sep"> - </span>{currentPlaying.title}</span>
                                    </marquee>
                                ) : (
                                    <div className="artist-track">
                                        <span className="track" ref={trackRef}>{currentPlaying.artist}</span>
                                        <span className="artist" ref={artistRef}><span className="sep"> - </span>{currentPlaying.title}</span>
                                        {addExtraSpan && <span className="extra"></span>}
                                    </div>
                                )}
                            </div>

                            { currentPlaying.info && audioDuration !== null &&
                                <span
                                    className="progress-bar"
                                    onClick={handleProgressBarClick}
                                >
                                    <span style={{ width: `${(currentPosition / audioDuration) * 100}%` }}></span>
                                </span>
                            }

                            { !currentPlaying.info &&
                                <span
                                    className="progress-bar"
                                >
                                    <span style={{ width: `${(currentPositionLive) * 100}%` }}></span>
                                </span>
                            }                                
                        </div>
                    </div>
                    <div className="col-2 d-block d-lg-none text-end"><span className="show-more"  onClick={switchActive}></span></div>
                    <div className="col-12 col-lg-6 col-xl-7 d-none d-lg-block footer-more active">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-12 col-xl-7"><div className="search-song-outer"><SearchSongs /></div></div>
                            <div className="col-12 col-xl-5 text-end d-none d-xl-block buttons">
                                <div className="btn-main-sm"><Link to='/musikwunsch'>Musikwunsch</Link></div>
                                <div className="btn-main-sm right"><Link to='/kontakt'>Kontakt</Link></div>
                            </div>
                        </div>
    
                                           
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AudioPlayer;
