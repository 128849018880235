import React, { useState, useEffect, useRef, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './index.css';

import { getHeaderFooter, getCurrentPlayingLive } from './helper/restapi.js';
import { Header } from './views/Header';
import ScrollToTop from './views/ScrollToTop.js';
import Page from './views/Page';
import Post from './views/Post';
import Team from './views/Team';
import AudioPlayer from './views/AudioPlayer';
import { Footer } from './views/Footer';
import { Loader } from './views/Loader.js'; // Assuming you have a Loader component

// Component for sending GTM page view events
const useGTMPageView = () => {
	const location = useLocation();

	useEffect(() => {
		TagManager.dataLayer({
			dataLayer: {
				event: 'pageview',
				page: {
					url: window.location.href,
					path: location.pathname,
				},
			},
		});
	}, [location]);
};

function Main({ isPlaying, toggleIsPlaying, currentPlaying, coverFlow }) {
	useGTMPageView(); // Track page views

	return (
		<Routes>
			<Route path="/" element={<Page isPlaying={isPlaying} toggleIsPlaying={toggleIsPlaying} currentPlaying={currentPlaying} coverFlow={coverFlow} />} />
			<Route path="/:slug" element={<Page isPlaying={isPlaying} toggleIsPlaying={toggleIsPlaying} currentPlaying={currentPlaying} />} />
			<Route path="/post/:slug" element={<Post isPlaying={isPlaying} toggleIsPlaying={toggleIsPlaying} currentPlaying={currentPlaying} />} />
			<Route path="/team/:slug" element={<Team isPlaying={isPlaying} toggleIsPlaying={toggleIsPlaying} currentPlaying={currentPlaying} />} />
		</Routes>
	);
}

export default function App() {
	const [content, setContent] = useState(null);
	const [isCookiesVisible, setIsCookiesVisible] = useState(false);

	const [isRadioThek, setIsRadioThek] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);
	const [currentPlaying, setCurrentPlaying] = useState(false);
	const [coverFlow, setCoverFlow] = useState(false);
	const [audioUrl, setAudioUrl] = useState("https://live.gruen-weiss.at/rgw.mp3");
	const isRadioThekRef = useRef(isRadioThek);

	const toggleIsPlaying = (stream) => {
		if (stream) {
			setAudioUrl(stream.audio);
			const radioThek = {
				artist: stream.serie,
				title: stream.title,
				cover: stream.image.sizes.thumbnail,
				info: 'radiothek',
			};
			setCurrentPlaying(radioThek);

			if ('mediaSession' in navigator) {
					navigator.mediaSession.metadata = new MediaMetadata({
							title: stream.title,
							artist: stream.serie,
							artwork: [
									{ src: stream.image.sizes.thumbnail, sizes: '512x512', type: 'image/jpeg' },
							],
					});
			}

			setIsPlaying(false);
			setIsRadioThek(true);
			setTimeout(() => {
				setIsPlaying(true);
			}, 200);
		} else {
			setIsPlaying(!isPlaying);
			setIsRadioThek(false);
		}
	};

	// Fetch header and footer data on initial load
	useEffect(() => {
		const fetchData = async () => {
			try {
				const [headerFooterData, currentPlayingData] = await Promise.all([
					getHeaderFooter(),
					getCurrentPlayingLive(),
				]);

				setContent(headerFooterData.data.data);
				setCurrentPlaying(currentPlayingData.data[2]);
				setCoverFlow(currentPlayingData.data);

				var data = currentPlayingData.data[2];
				var artist = data.artist;
				var title = data.title;
				var artwork = data.cover;

				if ('mediaSession' in navigator) {
						navigator.mediaSession.metadata = new MediaMetadata({
								title: title,
								artist: artist || 'Unknown Artist',
								artwork: [
										{ src: artwork, sizes: '512x512', type: 'image/png' },
								],
						});
				}

			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		if (!isRadioThek) {
			const interval = setInterval(async () => {
				try {
					const currentPlayingData = await getCurrentPlayingLive();
					setCurrentPlaying(currentPlayingData.data[2]);

					setCoverFlow(currentPlayingData.data);

					var data = currentPlayingData.data[2];
					var artist = data.artist;
					var title = data.title;
					var artwork = data.cover;

					if ('mediaSession' in navigator) {
							navigator.mediaSession.metadata = new MediaMetadata({
									title: title,
									artist: artist,
									artwork: [
											{ src: artwork, sizes: '512x512', type: 'image/png' },
									],
							});
					}
				} catch (error) {
					console.error('Error fetching current playing data:', error);
				}
			}, 10000);

			return () => clearInterval(interval); // Clear interval when `isRadioThek` changes or component unmounts
		}
	}, [isRadioThek]);

	useEffect(() => {
		isRadioThekRef.current = isRadioThek;
	}, [isRadioThek]);

	const handleAudioEnd = async () => {
		setAudioUrl('https://live.gruen-weiss.at/rgw.mp3');
		setIsPlaying(false);

		try {
			const currentPlayingData = await getCurrentPlayingLive();
			setCurrentPlaying(currentPlayingData.data[2]);
			setCoverFlow(currentPlayingData.data);
			setIsPlaying(true);
		} catch (error) {
			console.error('Error fetching current playing data:', error);
		}
	};

	useEffect(() => {
		const saved = localStorage.getItem('saved');
		setIsCookiesVisible(saved !== '1'); // Show cookies if "saved" is not 1
	}, []);

	const acceptAll = () => {
		localStorage.setItem('statistics', '1');
		localStorage.setItem('external', '1');
		localStorage.setItem('saved', '1');
		setIsCookiesVisible(false);
		initializeTagManager();
	};

	const savePreferences = () => {
		const statistics = document.getElementById('statistics').checked ? '1' : '0';
		const external = document.getElementById('external').checked ? '1' : '0';

		localStorage.setItem('statistics', statistics);
		localStorage.setItem('external', external);
		localStorage.setItem('saved', '1');

		setIsCookiesVisible(false);

		if (statistics === '1') {
			initializeTagManager();
		}
	};


	const initializeTagManager = () => {
		const tagManagerArgs = {
			gtmId: 'GTM-PP4L75G',
		};
		TagManager.initialize(tagManagerArgs);
	};

	useEffect(() => {
		if (localStorage.getItem('statistics') === '1') {
			initializeTagManager();
		}
	}, []);

	if (content === null) {
		return <Loader />;
	}

	return (
		<Suspense fallback={<Loader />}>
			<BrowserRouter>
				<ScrollToTop />
				<Header data={content} />
				<div id="main-content">
					<Main isPlaying={isPlaying} toggleIsPlaying={toggleIsPlaying} currentPlaying={currentPlaying} coverFlow={coverFlow} />
				</div>
				<Footer data={content} />
				<AudioPlayer
					onAudioEnd={handleAudioEnd}
					isPlaying={isPlaying}
					toggleIsPlaying={toggleIsPlaying}
					currentPlaying={currentPlaying}
					audioUrl={audioUrl}
				/>
			</BrowserRouter>

			{isCookiesVisible && (
				<div className="cookies">
					<div className="inner">
						<div className="text">
							<h4>Datenschutzeinstellungen</h4>
							<p>Wir nutzen Cookies auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern. Personenbezogene Daten können verarbeitet werden (z. B. IP-Adressen), z. B. für personalisierte Anzeigen und Inhalte oder Anzeigen- und Inhaltsmessung. Weitere Informationen über die Verwendung Ihrer Daten finden Sie in unserer Datenschutzerklärung. Sie können Ihre Auswahl jederzeit unter Einstellungen widerrufen oder anpassen. Einige Services verarbeiten personenbezogene Daten in den USA. Mit Ihrer Einwilligung zur Nutzung dieser Services stimmen Sie auch der Verarbeitung Ihrer Daten in den USA gemäß Art. 49 (1) lit. a DSGVO zu. Das EuGH stuft die USA als Land mit unzureichendem Datenschutz nach EU-Standards ein. So besteht etwa das Risiko, dass US-Behörden personenbezogene Daten in Überwachungsprogrammen verarbeiten, ohne bestehende Klagemöglichkeit für Europäer.</p>
							<p>Wenn Sie unter 16 Jahre alt sind und Ihre Zustimmung zu freiwilligen Diensten geben möchten, müssen Sie Ihre Erziehungsberechtigten um Erlaubnis bitten.</p>
						</div>
						<div className="controls">

							<div className="container">
								<div className="row">
									<div className="form-check col">
										<input className="form-check-input" type="checkbox" id="statistics" />
										<label className="form-check-label" htmlFor="statistics">Statistiken</label>
									</div>

									<div className="form-check col">
										<input className="form-check-input" type="checkbox" id="external" />
										<label className="form-check-label" htmlFor="external">Externe Medien</label>
									</div>
								</div>
							</div>
							<button className="btn btn-success w-100" onClick={acceptAll}>Alle akzeptieren</button>
							<button className="btn btn-outline-secondary w-100" onClick={savePreferences}>Speichern</button>
						</div>

					</div>
				</div>
			)}
		</Suspense>
	);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
