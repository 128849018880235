import { useNavigate, Link } from "react-router-dom";

export function Footer(data) {
	var company = data.data.data;



	const changeScroll = (item) => {
		localStorage.setItem("team-position", 0);
	};

	const itemsFooter = data.data.navfooter.map((item, index) => (
		<div key={index}>
			<Link to={item.slug} onClick={changeScroll(item)}>
				{item.title}
			</Link>
		</div>
	));

	return (
		<div id="footer">
			<div className="container">
				<div className="row align-items-end">	
					<div className="col-12 col-lg-4">
						<Link to="/">
							<img className="logo" src={company.logo_footer} />
						</Link>
						<div className="slogan">{company.slogan}</div>
					</div>
					<div className="col-12 col-lg-4">
						<div>{company.company.name}</div>
						<div>{company.company.street}</div>
						<div>{company.company.zip} {company.company.city}</div><br />
						<div>Telefon: <a href={"tel:" + company.company.phone}>{company.company.phone}</a></div>
						<div>E-Mail: <a href={"mailto:" + company.company.email}>{company.company.email}</a></div>
					</div>
					<div className="col-12 col-lg-4 text-lg-end mt-4 mt-lg-0">
						<div className="part mb-3">{itemsFooter}</div>
						<Link to="/impressum">Impressum</Link> | <Link to="/datenschutzerklaerung">Datenschutz</Link>
					</div>
				</div>
			</div>
		</div>
	);
}