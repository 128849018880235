import axios from 'axios';
var url = 'https://backend.gruen-weiss.at/api/v1/';
var secret = '1wvcGZszF0IjoxpcLzWZybWJaWwijU0MjYzNci5N6LmF0IdRwcli';
const config = {
	headers: {
		'Content-Type': 'multipart/form-data'
	}
};

export const getHeaderFooter = async () => {

	var endpoint = 'headerfooter';
	var endpoint = url + endpoint;

	config.headers.secret = secret;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const getPage = async (slug) => {

	var endpoint = 'page' + slug;
	var endpoint = url + endpoint;

	config.headers.secret = secret;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const getOnAir = async () => {

	var endpoint = 'on-air';
	var endpoint = url + endpoint;

	config.headers.secret = secret;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const getPost = async (slug) => {

	var endpoint = 'post' + slug;
	var endpoint = url + endpoint;

	config.headers.secret = secret;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const getPosts = async (posttypes, number) => {

	config.headers.secret = secret;

	var data = new FormData();

	var endpoint = 'posts';
	var endpoint = url + endpoint;

	data.append('type', 'website');

	posttypes.forEach((posttype, index) => {
		if (posttype == 'news') {
			data.append(`posttype[${index}]`, 'post');
		}
		if (posttype == 'events') {
			data.append(`posttype[${index}]`, 'event');
		}	
		if (posttype == 'werbung') {
			data.append(`posttype[${index}]`, 'ads');
		}		
		if (posttype == 'jobs') {
			data.append(`posttype[${index}]`, 'job');
		}	
		if (posttype == 'gewinnspiel') {
			data.append(`posttype[${index}]`, 'competition');
		}	
	});

	if (number) {
		data.append('number', number);
	}

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const sendFormApi = async(name, email, phone, city, artist, song, subject, message, time_open, time_sent, type) => {

	config.headers.secret = secret;

	var data = new FormData();

	var endpoint = 'send-form';
	var endpoint = url + endpoint;

	data.append('name', name);
	data.append('email', email);
	data.append('phone', phone);
	data.append('city', city);
	data.append('artist', artist);
	data.append('song', song);
	data.append('subject', subject);
	data.append('message', message);
	data.append('time_open', time_open);
	data.append('time_sent', time_sent);
	data.append('type', type);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}

}


export const sendFreeFormApi = async(fields) => {

	if (fields.form) {
		config.headers.secret = secret;

		var data = new FormData();

		var endpoint = 'send-free-form';
		var endpoint = url + endpoint;

		data.append('form', fields.form);
		data.append('data', JSON.stringify(fields));

		try {
			const response = await axios.post(endpoint, data, config);
			return response;
		} catch (error) {
			return error.response;
		}
	} else {
		alert('Es ist ein Fehler aufgetreten!');
	}



}

export const getTeam = async () => {

	var endpoint = 'team';
	var endpoint = url + endpoint;

	config.headers.secret = secret;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const getVotings = async () => {

	var endpoint = 'votings';
	var endpoint = url + endpoint;

	config.headers.secret = secret;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const sendVote = async (unique, song) => {

	var data = new FormData();

	var endpoint = 'votings';
	var endpoint = url + endpoint;

	data.append('unique', unique);
	data.append('song', song);

	try {
		const response = await axios.post(endpoint, data, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const getTeamMember = async (slug) => {

	var endpoint = 'team/' + slug;
	var endpoint = url + endpoint;

	config.headers.secret = secret;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const getTraffic = async () => {
	var endpoint = 'traffic-filtered';
	var endpoint = url + endpoint;

	config.headers.secret = secret;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}
};

export const getProgram = async () => {
	var endpoint = 'program';
	var endpoint = url + endpoint;

	config.headers.secret = secret;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}
};

export const getFrequencies = async (slug) => {

	var endpoint = 'frequencies';
	var endpoint = url + endpoint;

	config.headers.secret = secret;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const getAllFrequencies = async (slug) => {

	var endpoint = 'frequencies-all';
	var endpoint = url + endpoint;

	config.headers.secret = secret;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const getRadiothek = async (slug) => {

	var endpoint = 'radiothek';
	var endpoint = url + endpoint;

	config.headers.secret = secret;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}

};

export const getForm = async (id) => {

	var endpoint = 'form/' + id;
	var endpoint = url + endpoint;

	config.headers.secret = secret;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}

};


// export const getCurrentPlaying = async (slug) => {

// 	var endpoint = 'currentplaying';
// 	var endpoint = url + endpoint;

// 	config.headers.secret = secret;

// 	try {
// 		const response2 = await axios.get(endpoint, config);
// 		return response2;
// 	} catch (error1) {
// 		return { error1 };
// 	}
// 	try {
// 		const response1 = await axios.get('https://gruen-weiss.at/ta/onair.json');
// 		return response1;
// 	} catch (error1) {
// 		// try {
// 		// 	const response2 = await axios.get(endpoint, config);
// 		// 	return response2;
// 		// } catch (error2) {
// 		// 	return { error1, error2 };
// 		// }
// 	}

// };

export const getCurrentSongs = async (dateTime) => {
	try {
		const response = await axios.get('https://t.gruen-weiss.at/data/ta/abfrage.php' + dateTime);
		return response;
	} catch (error1) {
		return { error1 };
	}
};

export const getCurrentPlayingLive = async () => {
	try {
		const response = await axios.get('https://t.gruen-weiss.at/data/ta/onair.json');
		//const response = await axios.get('https://gruen-weiss.at/ta/onair.json');
		return response;
	} catch (error1) {
		return { error1 };
	}
};

export const getSentPositions = async () => {

	var endpoint = 'receive-livestatus';
	var endpoint = url + endpoint;

	config.headers.secret = secret;

	try {
		const response = await axios.get(endpoint, config);
		return response;
	} catch (error) {
		return error.response;
	}

};


