import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { useLocation } from "react-router-dom";

import { getTeamMember } from '../helper/restapi.js';

import { Loader } from './Loader.js';
import { Elements } from './Elements.js';

function withParams(Component) {
  return props => <Component {...props} location={useLocation()} />;
}


class Post extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			data: [],
		};
	}

	async getPost(slug) {
		const response = await getTeamMember(slug);
		if (response.data.data) {
			this.setState({
				data: response.data.data,
				isReady: true
			})
		}
			this.setState({
				isReady: true
			})
	}

	componentDidMount() {
		var slug = this.props.location.pathname;
		slug = slug.substring(slug.lastIndexOf('/') + 1);
		this.getPost(slug);
	}


	render() {

		const { data } = this.state;

		if (!this.state.isReady) {
			return (
				<Loader />
			);
		}

		if (data.image) {
			var imageMd = data.image.sizes['banner-start'];
			var imageLg = data.image.sizes['banner-start'];			
		}


		var categories = data.categories;
		var categories = categories.join(', ')
		
		var info = data.info;
		var likes = data.likes;
		var questions = data.questions;

		return (
			<>
				<Helmet>
					<title>{data.meta_title}</title>
					<meta name="description" content={data.meta_description} />
				</Helmet>
				
				<div className="banner_start banner_team team_detail">
					<div className="container picture">
						<picture>
							<source media="(max-width: 767px)" srcSet={imageMd} />
							<source media="(max-width: 768px)" srcSet={imageLg} />
							<img src={imageLg} alt="Radio Grün Weiß" />
						</picture>
					</div>
					<div className="overlay">
						<div className="container">
							<div className="col-12 col-lg-6 offset-lg-6 p-0">
								<div className="row">
									<div className="col-12">
										<h1 className="slogan ">{data.name}</h1>
										{ categories && info &&
											<div className="infobox">
												<div className="categories">
													{ categories }
												</div>
												<div className="infos">
													{info && info.length > 0 && info.map((item, index) => (
														<div key={index} className="col-12 info">
															<div className="q">{item.label}</div>
															<div className="a">{item.value}</div>
														</div>
													))}
												</div>
											</div>
										}
									</div>
								</div>
							</div>
						</div>
						<a href="#down" className="d-none d-xl-block scrolldown"><img src="/icon/arrow-down.svg" /></a>
					</div>
				</div>
				<div id="down"></div>
				<div className="container team-member">
					<div className="row">
						<div className="col-12 col-lg-4 likes mb-5 mb-lg-0">
							{ likes &&
								<div className="inner h-100">
									<h2>Lieblings...</h2>
									{likes && likes.length > 0 && likes.map((item, index) => (
										<>
											{ item.value &&
												<div key={index} className="like">
													<div className="q">{item.label}</div>
													<div className="a" dangerouslySetInnerHTML={{ __html: item.value }} />
												</div>
											}
										</>
									))}
								</div>
							}
						</div>
						<div className="col-12 col-lg-8 questions mt-5 mt-lg-0">
							{ questions &&
								<div className="inner">
									<h2>12 Fragen</h2>
									{questions && questions.length > 0 && questions.map((item, index) => (
										<>
											{ item.value &&
												<div key={index} className="question">
													<div className="q">{item.label}</div>
													<div className="a" dangerouslySetInnerHTML={{ __html: item.value }} />
												</div>
											}
										</>
									))}
								</div>
							}
						</div>
					</div>
				</div>
			</>
		);
	}

}

export default withParams(Post);
