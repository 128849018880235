import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link, useLocation } from "react-router-dom";

export function Header(data) {

	const [isNavActive, setIsNavActive] = useState(false);
	const [isHome, setIsHome] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);
	const { pathname } = useLocation();

	useEffect(() => {
		setIsNavActive(false);
		if (pathname == '/') {
			setIsHome(true);
		} else {
			setIsHome(false);
		}
	}, [pathname]);

	const handleNavToggle = () => {
		setIsNavActive(!isNavActive);
	};

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

	var company = data.data.data;
	var nav = data.data;

	return (
		<div id="header" className={isScrolled ? 'scrolled' : ''}>
			<div className="container">
				<div className="row">	
					<div className="col-6">
						{ !isHome &&
							<Link to="/">
								<img className="logo" src={company.logo} />
							</Link>
						}
						{ isHome &&
							<a href="#">
								<img className="logo" src={company.logo} />
							</a>
						}
					</div>
					<div className="col-6 part-right">
						<div className="row align-items-center part-right-inner">
							<div className="col-8 part-right-left">
								<a href={company.company.facebook} title="Radio Grün Weiß auf Facebook" target="_blank"><img className="" src="/icon/rgw-facebook.png" /></a>
								<a href={company.company.instagram} title="Radio Grün Weiß auf Instagram" target="_blank"><img className="" src="/icon/rgw-insta.png" /></a>
								<a href={'https://wa.me/' + company.company.whatsapp} title="Radio Grün Weiß auf WhatsApp" target="_blank"><img className="" src="/icon/rgw-wa.png" /></a>
								<a className="wa d-none d-lg-inline-block" href={'https://wa.me/' + company.company.whatsapp} title="Radio Grün Weiß auf WhatsApp" target="_blank">
									Whats App ins Studio<span>0664 / 22 22 994</span>
								</a>
							</div>
							<div className="col-4 text-end part-right-right">
								<div
									className={`nav-trigger d-inline-block ${isNavActive ? 'active' : ''}`}
									onClick={handleNavToggle}
								>
									<span className="bar-1"></span><span className="bar-2"></span><span className="bar-3"></span>
								</div>
								<HeaderNav nav={nav} active={isNavActive} handleNavToggle={handleNavToggle} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export function HeaderNav({nav, active, handleNavToggle}) {
	const navItems = nav.nav;
	const navSideItems = nav.navside;
	const tileTop = nav.tile_top;
	const tileBottom = nav.tile_bottom;

    const [widths, setWidths] = useState([]); // Store widths for each item
    const itemRefs = useRef([]);

	useEffect(() => {
	// Measure widths after the items are rendered
		const newWidths = itemRefs.current.map(ref => ref?.offsetWidth || 0);
		setWidths(newWidths); // Update state with widths
	}, [nav]);

	const { pathname } = useLocation();

	useEffect(() => {
		if (nav.active) {
			const liElements = document.querySelectorAll('#header .part-right .navigation.active li a');

			liElements.forEach((li, index) => {
				const realWidth = li.offsetWidth;
				li.style.width = `${realWidth}px`;
				setTimeout(function () {
					li.style.width = '100%';

					setTimeout(function () {
						li.style.width = '0%';
						setTimeout(function () {
							li.style.width = `${realWidth + 20}px`;
						}, 300);
					}, 500);
				}, 100*index);
			});

		}
	}, [nav.active]);

	const items = navItems.map((item, index) => (
		<li key={index} className={'/' + item.slug === pathname ? 'active' : ''}>
			{ '/' + item.slug === pathname &&
				<Link
					to={item.slug}
					style={{ width: widths[index] ? `${widths[index]}px` : 'auto' }} // Set inline width
					ref={(el) => (itemRefs.current[index] = el)}
					onClick={handleNavToggle}
				>
					<span className="d-none">0{ index +1 }</span>
					{item.title}
					<img className="before" src="/icon/eumel.svg" />
				</Link>
			}
			{ '/' + item.slug !== pathname &&
				<Link
					to={item.slug}
					style={{ width: widths[index] ? `${widths[index]}px` : 'auto' }} // Set inline width
					ref={(el) => (itemRefs.current[index] = el)}
				>
					<span className="d-none">0{ index +1 }</span>
					{item.title}
					<img className="before" src="/icon/eumel.svg" />
				</Link>
			}
		</li>
	));

	const itemsSide = navSideItems.map((item, index) => (
		<div key={index} className={'/' + item.slug === pathname ? 'item active' : 'item'}>
			<Link to={item.slug}>
				<img className="before" src="/icon/regler.svg" />{item.title}
			</Link>
		</div>
	));

	return (
		<>
			<div
				className={`closer ${active ? 'active' : ''}`}
				onClick={handleNavToggle}
			></div>
			<div className={`navigation ${active ? 'active' : ''}`}>
				<div
					className="nav-trigger active"
					onClick={handleNavToggle}
				>
					<span className="bar-1"></span><span className="bar-2"></span><span className="bar-3"></span>
				</div>
				<div className="row">
					<div className="col-12 col-lg-12">
						<div className="left">
							<ul>{items}</ul>
						</div>
					</div>
					<div className="col-12 col-lg-12">
						<div className="right">
							<div className="row">
								<div className="col-12 col-md-6">
									<div className="part sidenav">{itemsSide}</div>
								</div>
								<div className="col-12 col-md-6">
									<Link
										to={tileTop.link.post_name}
										className="tile-top h-50"
										style={{ background: 'url(' + tileTop.image.sizes['news-list'] + ')' }}
									>
										<span>{ tileTop.text }</span>
									</Link>
									<div
										className="tile-bottom h-50"

									>
										<Link
											to={tileBottom.link.post_name}
											className="inner"
											style={{ background: 'url(' + tileBottom.image.sizes['news-list'] + ')' }}
										>
											<span>{ tileBottom.text }</span>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
